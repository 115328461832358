import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import 'simplebar';
import Papa from 'papaparse';
import { get } from 'axios';
import 'simplebar/dist/simplebar.css';
import FileSaver from 'file-saver';
import { BiSolidError, BiSolidInfoCircle, BiX, BiCheck } from 'react-icons/bi';
import { FaCheckSquare } from 'react-icons/fa';
import { Table } from './Components/EditTable';
import BitaCheckbox from '../../components/BitaCheckbox/BitaCheckbox';
import BitaCalendarDate from '../../components/BitaCalendar/BitaCalendarDate';
import BitaButton from '../../bitaComponents/BitaButton/BitaButton';
import BitaSelect from '../../components/BitaSelect/BitaSelect';
import BitaInput from '../../components/BitaInput/BitaInput';
import Switcher from '../../bitaComponents/Switcher/Switcher';
import BitaTable from '../../bitaComponents/BitaTable/BitaTable';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import styles from './index-builder.module.css';
import style from './PortfolioBasketCalculation.module.scss';
import { ReactComponent as Logo } from '../../static/img/Icons/BITA.svg';
import { toastInterceptor } from '../../utils/utils';
import { LIST_TIMEZONE_LIVE_BASKET } from '../../constants/timezome-list-constants';
import { DATA_DUMMIE_BACKTEST, DUMMIE_PREFIX } from './utils/dataDummie';
import { URL_CONSTANTS } from '../../api-data-mapping/url-constants';
import { createBasket, uploadFileSettings, coverageRequest, getBasketByTicker } from './utils/api';
import { CURRENCIES_ARRAY, DATA_LIST_DROPDOWN, BUSINESS_DAYS } from './utils/constants';
import RequestCoverageTable from './Components/RequestCoverageTable';

import './Portfolio.css';

const getMIC = (tokenUser, data) => {
  return get(URL_CONSTANTS.ACE_MIC_ALL_URL, {
    headers: { Authorization: `Bearer ${tokenUser}` },
  });
};

const basketButton = {
  'Long only Basket': [
    {
      type: 'Button',
      label: 'Submission with Weights',
      url: 'divisor_basket_input_with_open_weight',
    },
    {
      type: 'Button',
      label: 'Submission with Shares',
      url: 'divisor_basket_input_with_shares_and_without_custom_prices',
    },
    {
      type: 'Button',
      label: 'Submission with Notionals',
      url: 'divisor_basket_input_with_divisor_and_notional',
    },
  ],
  'Bottom-Up Basket': [{ type: 'Button', label: 'Global Template', url: 'global_input' }],
  'Long/Short': [{ type: 'Button', label: 'Long/short Template', url: 'long_short_input' }],
};
const timezone = LIST_TIMEZONE_LIVE_BASKET.sort().map(item => {
  return {
    value: item.region,
    id: item.region,
    name: item.region,
    timezone: item.timezone,
    calculationZone: item.calculationZone,
  };
});

const styleClose = {
  position: 'absolute',
  fontSize: '30px',
  fontWeight: 'bold',
  top: '-10px',
  right: '0',
  cursor: 'pointer',
  padding: '10px',
  boxSizing: 'border-box',
};

// TODO Function to format date to yyyy-mm-dd (improve this because is the same function of UpdateBasket)
const formatDateString = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

const isValidDateFormat = dateString => {
  let isValid = false;
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    isValid = false;
  }

  const date = new Date(dateString);
  isValid = date.toISOString().slice(0, 10) === dateString;

  return isValid;
};

const validateData = [
  'timezone',
  'symbol',
  'calculation_setup',
  'deployment_setup',
  'currency',
  'calculation_type',
  'calculation_zone',
  'cash_distribution',
  'application_point',
  'dissemination',
];

const validatedFieldsValues = {
  currency: CURRENCIES_ARRAY,
  calculation_setup: ['EOD', 'EOD_INTRADAY'],
  calculation_zone: ['us_only', 'us_europe', 'global', 'europe_only'],
  deployment_setup: ['EOD', 'IMMEDIATELY'],
  calculation_type: ['NET_RETURN', 'TOTAL_RETURN', 'PRICE_RETURN'],
  cash_distribution: [
    'DISTRIBUTION_ACROSS_BASKET',
    'DISTRIBUTION_ON_PAYING_SECURITY',
    'ADD_DISTRIBUTION_TO_CASH',
  ],
  application_point: ['AT_MARKET_CLOSE', 'AT_MARKET_OPEN'],
  dissemination: ['BITA Only'],
};

const DATA_APPLICATION_POINT = [
  {
    value: 'At market close',
    id: 'AT_MARKET_CLOSE',
  },
  {
    value: 'At market open',
    id: 'AT_MARKET_OPEN',
  },
];
const DATA_CASH_DISTRIBUTION = [
  {
    value: 'Reinvest distributions across basket',
    id: 'DISTRIBUTION_ACROSS_BASKET',
  },
  {
    value: 'Reinvest distributions on paying security',
    id: 'DISTRIBUTION_ON_PAYING_SECURITY',
  },
  { value: 'Add distributions to cash', id: 'ADD_DISTRIBUTION_TO_CASH' },
];

const CALCULATION_ZONE_MAPPING = {
  us_only: 'US / Eastern',
  us_europe: 'US / Europe',
  global: 'Global',
  europe_only: 'Europe only',
};

const INITIAL_PAGINATION = {
  pageIndex: 0,
  pageSize: 10,
};

function truncateString(str) {
  if (str.length > 80) {
    return `${str.substring(0, 80)}...`;
  }
  return str;
}

// Verifies that a date is from today or later (and not a date in the past). For example: valid dates: today date, tomorrow date. Invalid dates: yesterday date, last month date, etc.
function isValidDateString(dateString) {
  // Check if the date string matches the format yyyy-mm-dd
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date string into a Date object
  const date = new Date(`${dateString}T00:00:00`); // Treat as local midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize today's date to local midnight

  // Check if the date is today or in the future
  return date >= today;
}

const SubmitBasket = () => {
  const [files, setFiles] = React.useState('');
  const [filesBacktest, setFilesBacktest] = React.useState('');
  const user = useSelector(state => state.auth);
  const authOrgPrefix = useSelector(state => state.auth?.org_prefix);
  const [type, setType] = useState('Long only Basket');
  const [data, setData] = useState({});
  const [dataInitial, setDataInitial] = useState({});
  const [launchDate, setLaunchDate] = useState(null); // Now the initial state is null instead of today date
  const [errorCalendar, setErrorCalendar] = useState(false);
  const [errorReferenceData, setErrorReferenceData] = useState(false);
  const [listMIC, setListMIC] = useState([]);
  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [isActiveAnnualizedYearlyDecrement, setIsActiveAnnualizedYearlyDecrement] = useState(false);
  const [constituentsError, setConstituentsError] = useState([]);
  const [constituentsDelete, setConstituentsDelete] = useState([]);
  const [dataBacktest, setDataBacktest] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalRequestCoverage, setShowModalRequestCoverage] = useState(false);
  const [showModalRequestCoverageSuccessful, setShowModalRequestCoverageSuccessful] = useState(
    false,
  );
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showSedolNotFoundModal, setShowSedolNotFoundModal] = useState(false);
  const [sedolsList, setSedolsList] = useState([]);
  const [enableDivisorOrStartingValue, SetEnableDivisorOrStartingValue] = useState({
    statusDivisor: true,
    statusStarting: false,
  });

  const history = useHistory();
  const inputBasketRef = useRef(null);
  const inputBacktestRef = useRef(null);
  const { token } = user;
  const [orgPrefix, setOrgPrefix] = useState(authOrgPrefix || '');

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
    if (name === 'calculationZone') {
      const validCalculationZones = validatedFieldsValues.calculation_zone;
      const timeData = LIST_TIMEZONE_LIVE_BASKET.find(item => item.region === value);
      setData({
        ...data,
        region: value,
        timezone: timeData?.timezone,
        calculation_close_time: timeData?.calculationEnd,
        calculation_open_time: timeData?.calculationStart,
        calculation_zone: timezone.find(item => item?.name === value)?.calculationZone,
      });
    }
  };

  const cleanStates = () => {
    setShowModal(false);
    setShowModal(false);
    // setData(null);
    setFiles('');
    setFilesBacktest('');
    SetEnableDivisorOrStartingValue({
      statusDivisor: true,
      statusStarting: false,
    });

    // new states to clean
    setErrorCalendar(false);
    setLaunchDate(null);

    setSedolsList([]);
    setShowSedolNotFoundModal(false);
    setShowModalDelete(false);
    setShowModalRequestCoverageSuccessful(false);
    setShowModalRequestCoverage(false);
    setShowModalConfirm(false);
    setDataBacktest([]);
    setConstituentsDelete([]);
    setConstituentsError([]);
    setIsActiveAnnualizedYearlyDecrement(false);
    setData({});
    setType('Long only Basket');
  };

  const cleanAllStates = () => {
    cleanStates();

    // clean the refs
    if (inputBasketRef.current) {
      inputBasketRef.current.value = ''; // Basket ref
    }
    if (inputBacktestRef.current) {
      inputBacktestRef.current.value = ''; // Backtest ref
    }
  };

  const validarData = (dataSend, list) => {
    return list.every(
      propertyValidate =>
        propertyValidate in dataSend &&
        typeof dataSend[propertyValidate] === 'string' &&
        dataSend[propertyValidate].trim() !== '',
    );
  };

  const validateFields = (key, field) => {
    if (!key || !field) return false;
    const acceptedValues = validatedFieldsValues[key];
    return !!acceptedValues?.includes(field);
  };

  useEffect(() => {
    getMIC(token)
      .then(rsp => {
        setListMIC(Object.values(rsp?.data?.data));
      })
      .catch(error => {
        toastInterceptor(error);
      });
  }, []);

  const BasketTable = ({ titleBasketTable, deleteBasketTable, buttonsBasketTable, scroll }) => {
    const renderTable = () => {
      if (type === 'Long only Basket' && data?.meta_reference_data) {
        const dataRows = data?.meta_reference_data ?? data.metadata ?? [];
        const columns = Object.keys(dataRows?.[0] ?? {}).filter(item => item !== 'type');

        return (
          <div className="contentTableBasketPreview">
            {scroll ? (
              <Table
                rows={dataRows}
                setMetadata={setData}
                listMic={listMIC}
                setConstituentsDelete={setConstituentsDelete}
                constituentsDelete={constituentsDelete}
                setErrorReferenceData={setErrorReferenceData}
                pagination={pagination}
                setPagination={setPagination}
              />
            ) : (
              <BitaTable
                id="basket"
                data={dataRows ?? []}
                height="100%"
                style={{ padding: '0' }}
                search={false}
                scroll={!scroll}
                showFields={deleteBasketTable ? [...(columns ?? [])] : columns ?? []}
                selectAction={() => {}}
                editButton={false}
                CustomRowComponent={({ data: dataValue, entryKey, value }) => (
                  <div
                    className="custom-row-container"
                    style={{ width: entryKey === 'ticker' ? '100px' : '' }}
                  >
                    <p className={`${entryKey === 'type' ? 'info-icon-live' : ''}`}>
                      {entryKey === 'type' && deleteBasketTable ? (
                        value === 'with_coverage' ? (
                          <FaCheckSquare className="icon-info" />
                        ) : (
                          <BiSolidError className="icon-error" />
                        )
                      ) : entryKey === 'isin' && deleteBasketTable ? (
                        <div style={{ display: 'flex' }}>
                          <BitaCheckbox
                            checked={constituentsDelete
                              .map(item => item?.isin ?? item?.sedol)
                              .includes(dataValue?.isin ?? dataValue?.sedol)}
                            handleChange={checked => {
                              if (checked) {
                                setConstituentsDelete([
                                  ...constituentsDelete,
                                  dataValue,
                                  // dataValue?.isin ?? dataValue?.sedol,
                                ]);
                              } else {
                                setConstituentsDelete(
                                  constituentsDelete.filter(
                                    item =>
                                      (item?.isin ?? item?.sedol) !==
                                      (dataValue?.isin ?? dataValue?.sedol),
                                  ),
                                );
                              }
                            }}
                          />
                          {value}
                        </div>
                      ) : (
                        value ?? '-'
                      )}
                    </p>
                    {entryKey === 'type' && value !== 'with_coverage' && (
                      <div className="tooltip error-tooltip">
                        <div>
                          <BiX className="tooltip-icon icon-error" />
                        </div>
                        <span>
                          This instrument is not under the BITA Calculation platform pricing
                          coverage. You can request the coverage using the button below &quot;
                          Request Coverage &quot;. We will receive the request, process it, and
                          communicate with you via e-mail as soon as possible.
                        </span>
                      </div>
                    )}
                    {entryKey === 'type' && value === 'with_coverage' && (
                      <div className="tooltip success-tooltip">
                        <div>
                          <BiCheck className="tooltip-icon icon-info" />
                        </div>
                        <span>The input has been successfully validated</span>
                      </div>
                    )}
                  </div>
                )}
                // eslint-disable-next-line no-shadow
              />
            )}
          </div>
        );
      }

      // Hide this for now
      // if (type === 'Bottom-Up Basket' && data.children_symbols) {
      //   const rows = data.children_symbols.map(symbol => ({
      //     children_symbols: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>
      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['children_symbols']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      // Hide this for now
      // if (type === 'Long/Short' && data.global_baskets) {
      //   const rows = Object.values(data.global_baskets).map(symbol => ({
      //     basket_symbol: symbol,
      //   }));

      //   return (
      //     <>
      //       <div style={{ border: 'none', marginBottom: '0px' }}>Baskets</div>

      //       <BitaTable
      //         data={rows}
      //         search={false}
      //         height="300px"
      //         showFields={['basket_symbol']}
      //         selectAction={() => {}}
      //         CustomRowComponent={({ value }) => (
      //           <div>
      //             <p>{value}</p>
      //           </div>
      //         )}
      //       />
      //     </>
      //   );
      // }

      if (type === 'Decrement Index') {
        return <></>;
      }
      return null;
    };

    return <div className={titleBasketTable ? '' : style.contentWidget}>{renderTable()}</div>;
  };

  const BacktestTable = () => {
    const renderTable = () => {
      const columns = Object.keys(dataBacktest?.[0]);
      const rows = dataBacktest.slice(1);

      return (
        <>
          <div style={{ border: 'none', marginBottom: '0px' }}>Backtest Preview</div>
          <BitaTable
            data={rows}
            search={false}
            showFields={columns}
            height="300px"
            showOnly={10}
            pagination
            selectAction={() => {}}
            CustomRowComponent={({ value }) => (
              <div>
                <p style={{ paddingRight: '20px' }}>{value}</p>
              </div>
            )}
          />
        </>
      );
    };
    return <div className={style.contentWidget}>{renderTable()}</div>;
  };

  const fieldsValidations = () => {
    try {
      if (!data) return false;

      return (
        data?.meta_reference_data &&
        validarData(data, validateData) &&
        validateFields('cash_distribution', data?.cash_distribution) &&
        validateFields('application_point', data?.application_point) &&
        validateFields('calculation_type', data?.calculation_type) // This is return type
      );
    } catch (error) {
      console.error('Validation error:', error);
      return false;
    }
  };

  const existRequestCoverage = () => {
    let coverage = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data?.meta_reference_data?.length; i++) {
      if (data?.meta_reference_data[i]?.type !== 'with_coverage') {
        coverage = true;
        break;
      }
    }
    return coverage;
  };

  const applyButtonValidations = () => {
    const { meta_reference_data, currency, divisor, starting_value } = data || {};
    const { statusDivisor, statusStarting } = enableDivisorOrStartingValue;

    // Check if there is valid basket data or backtest data
    const hasValidBasketDataOrBacktestData = meta_reference_data || dataBacktest.length > 0;

    // Check if there is exchange data available
    const hasExchangeData = meta_reference_data?.[0]?.exchange || false;
    const hasMetadataExists = meta_reference_data?.length > 0;

    // Function to validate if either the divisor or starting value is valid
    const isDivisorOrStartingValueValid = () => {
      return (divisor > 0 && statusDivisor) || (starting_value > 0 && statusStarting);
    };

    // Object containing all the validation checks
    const validations = {
      launchDate: !errorCalendar, // Checks if there is no launch date error
      validateData: !errorReferenceData,
      basketDataOrBacktestData: hasValidBasketDataOrBacktestData, // Checks if there is valid basket or backtest data
      requestCoverage: !existRequestCoverage(), // Checks if there is no request coverage
      exchangeData: hasExchangeData, // Checks if exchange data is present
      hasMetadataExists, // Check is metadata exists
      fields: fieldsValidations(), // Checks if fields are valid
      currency: currency && validateFields('currency', currency), // Validates currency
      divisorOrStartingValue: isDivisorOrStartingValueValid(), // Checks if divisor or starting value is valid
    };

    // Returns true if any of the validations fail (i.e., are false)
    return Object.values(validations).some(validation => !validation);
  };

  const handleApplyBtn = () => {
    const fullTicker = orgPrefix + data?.symbol;

    getBasketByTicker(token, fullTicker)
      .then(rsp => {
        if (rsp.data?.data?.symbol === fullTicker) {
          toast.error(
            `Ticker ${data?.symbol} already exists in this organization. Please provide a different one`,
          );
        } else {
          setShowModalConfirm(true);
          if (data?.divisor > 0 && enableDivisorOrStartingValue.statusDivisor) {
            const { starting_value: _, ...updatedData } = data;
            setData({ ...updatedData });
          } else if (data?.starting_value > 0 && enableDivisorOrStartingValue.statusStarting) {
            const { divisor: _, ...updatedData } = data;
            setData({ ...updatedData });
          }
        }
      })
      .catch(error => {
        toastInterceptor(error);
      });
  };

  return (
    <div>
      {/* Basket Composition/Portfolio */}
      <div className={styles.compositionPorfolio}>
        <div className={styles.compositionPorfolioTitle}>Basket Composition/Portfolio</div>
        <div className={style.contentDataUpload}>
          <div className={style.dataUpload}>
            <div>
              <div className={style.subtitle}>RETURN TYPE</div>
              <div style={{ marginTop: '10px' }}>
                <BitaSelect
                  warningIcon
                  selected={type}
                  value={{ value: type, id: type, name: type }}
                  label="level"
                  data={DATA_LIST_DROPDOWN}
                  handleSelection={value => setType(value.value)}
                  style={{ fontSize: 13 }}
                />
              </div>
            </div>

            {type !== 'Decrement Index' && (
              <>
                <div className={style.contentTemplate}>
                  <div className={style.subtitle}>TEMPLATES</div>
                  <div
                    style={{ display: 'flex', gap: '20px', marginTop: '10px', flexWrap: 'wrap' }}
                  >
                    {Object.values(basketButton[type] ?? {}).map(button => (
                      <BitaButton
                        primaryWhite
                        width="160px"
                        height="35px"
                        onClick={() => {
                          FileSaver.saveAs(
                            `${process.env.PUBLIC_URL}/files/${button?.url}.csv`,
                            `${button?.url}.csv`,
                          );
                        }}
                      >
                        {button?.label}
                      </BitaButton>
                    ))}
                  </div>
                </div>

                <div>
                  <div className={style.subtitle}>Portfolio Uploader</div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '10px',
                      gap: '20px',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <input
                      type="file"
                      onChange={event => {
                        cleanStates(); // without clearing the useRefs
                        setFiles(event.target.files[0]);
                      }}
                      ref={inputBasketRef}
                      name="file"
                      id="file"
                      className={style.inputfile}
                    />
                    <label htmlFor="file">Select File</label>
                    <BitaButton
                      disabled={!files}
                      width="160px"
                      onClick={() => {
                        const formData = new FormData();
                        formData.append('template', files);
                        if (type === 'Long only Basket') {
                          formData.append('type', 'basket');
                          // formData.append('org_prefix', orgPrefix); // Remove for now
                        } else {
                          formData.append(
                            'type',
                            type.includes('Bottom') ? 'global' : type.replace('/', '_'),
                          );
                        }
                        setData({});
                        setDataBacktest([]);
                        setFilesBacktest('');
                        if (files) {
                          uploadFileSettings(token, formData)
                            .then(rsp => {
                              toast('Your file has been processed successfully');
                              // const constituents = Object.keys(rsp.data.data?.meta_reference_data.awf);
                              const constituents = rsp.data.data?.meta_reference_data;
                              const constituentsWithError = constituents.filter(
                                (constituyente, index) => index % 3 === 0,
                              );
                              setConstituentsError(constituentsWithError);
                              const timeData = LIST_TIMEZONE_LIVE_BASKET.find(
                                item => item.calculationZone === rsp.data.data?.calculation_zone,
                              );
                              const dataFormated = {
                                ...rsp.data.data,
                                calculation_zone: validateFields(
                                  'calculation_zone',
                                  rsp.data.data?.calculation_zone,
                                )
                                  ? rsp.data.data?.calculation_zone
                                  : '',
                                region: timeData?.region,
                                timezone: timeData?.timezone,
                                calculation_close_time: timeData?.calculationEnd,
                                calculation_open_time: timeData?.calculationStart,
                                start_week_day: timeData?.startWeekDay,
                                end_week_day: timeData?.endWeekDay,
                                dissemination: 'BITA Only',
                                divisor: rsp.data.data?.divisor,
                              };
                              setData(dataFormated);
                              setPagination(INITIAL_PAGINATION);
                              const launchDateString = rsp.data.data?.launch_date;
                              if (launchDateString) {
                                if (isValidDateFormat(launchDateString)) {
                                  if (isValidDateString(launchDateString)) {
                                    // it is a valid date
                                    const date = new Date(launchDateString);
                                    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
                                    setLaunchDate(date);
                                    setErrorCalendar(false);
                                  } else {
                                    // it is not a valid date, so the user should add it manually
                                    setLaunchDate(null);
                                    setErrorCalendar(true);
                                  }
                                } else {
                                  // It is not in the correct format, so the user should add it manually
                                  setLaunchDate(null);
                                  setErrorCalendar(true);
                                }
                              } else {
                                // The launch date is not included in the API response, so add today's date
                                setLaunchDate(new Date());
                                setErrorCalendar(false);
                              }
                              setDataInitial(dataFormated);

                              inputBasketRef.current.value = '';

                              const sedolNotFoundArr = rsp.data.data?.sedols_not_found || null;
                              if (sedolNotFoundArr && sedolNotFoundArr.length > 0) {
                                setSedolsList(sedolNotFoundArr);
                                setShowSedolNotFoundModal(true);
                              }

                              // set Divisor and start value first condition
                              if (rsp.data.data?.starting_value && !rsp.data.data?.divisor) {
                                SetEnableDivisorOrStartingValue({
                                  statusDivisor: false,
                                  statusStarting: true,
                                });
                              }
                            })
                            .catch(error => {
                              toastInterceptor(error);
                            });
                        } else {
                          toast.error(
                            'Please upload a portfolio template with a different file name.',
                          );
                        }
                      }}
                      primary
                      height="35px"
                    >
                      Upload File
                    </BitaButton>
                  </div>
                  <p className={style.nameFile}>{files && `(${files?.name})`}</p>
                </div>
              </>
            )}
          </div>
          <div>
            {data?.meta_reference_data && (
              <div
                className="contentTableBasketPreview"
                style={{ marginTop: '0px', paddingTop: '0px' }}
              >
                <div className="headerTable">
                  <div style={{ border: 'none', marginBottom: '0px' }}>Basket Data Preview</div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h1>General file information section</h1>
                  </div>
                </div>
                {data && (
                  <div style={{ height: '600px' }}>
                    <div>
                      <BasketTable titleBasketTable deleteBasketTable buttonsBasketTable scroll />
                    </div>
                  </div>
                )}
                {data && existRequestCoverage() && (
                  <BitaButton
                    disabled={
                      data?.meta_reference_data.filter(item => item.type !== 'with_coverage')
                        .length === 0
                    }
                    primary
                    width="160px"
                    style={{ marginRight: 'auto', marginTop: '20px' }}
                    height="35px"
                    onClick={() => setShowModalRequestCoverage(true)}
                  >
                    Request Coverage
                  </BitaButton>
                )}

                {data && true && (
                  <BitaButton
                    disabled={constituentsDelete.length === 0}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Delete Constituent
                  </BitaButton>
                )}
                {/* {data && true && (
                  <BitaButton
                    disabled={true}
                    primary
                    width="160px"
                    style={{ marginLeft: '20px', marginTop: '20px' }}
                    height="35px"
                    onClick={() => {
                      setShowModalDelete(true);
                    }}
                  >
                    Add Instrument
                  </BitaButton>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Backtest Section */}
      {type !== 'Decrement Index' && (
        <>
          <div className={styles.compositionPorfolio}>
            <div className={styles.compositionPorfolioTitle}>Backtest</div>
            <div className={style.contentDataUpload}>
              <div className={style.dataUpload}>
                <div>
                  <div>
                    <div className={style.subtitle}>TEMPLATES</div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        marginTop: '10px',
                        marginBottom: '20px',
                        flexWrap: 'wrap',
                      }}
                    >
                      <BitaButton
                        primaryWhite
                        width="160px"
                        height="35px"
                        onClick={() => {
                          FileSaver.saveAs(
                            `${process.env.PUBLIC_URL}/files/backtest_template.csv`,
                            `backtest_template.csv`,
                          );
                        }}
                      >
                        Backtest Template
                      </BitaButton>
                    </div>
                  </div>
                  <div className={style.subtitle}>Backtest Uploader</div>
                  <div
                    className={style.containerButtonsUpload}
                    style={{
                      pointerEvents: files?.name ? 'auto' : 'none',
                      opacity: files?.name ? 1 : 0.5,
                    }}
                  >
                    <div>
                      <input
                        type="file"
                        accept=".csv"
                        onChange={event => {
                          const file = event.target.files[0];
                          setFilesBacktest(file);
                          setDataBacktest({});
                        }}
                        style={{ display: 'none' }}
                        id="file-upload"
                      />
                      <label htmlFor="file-upload" className={styles.buttonUpload}>
                        Select File
                      </label>
                      <div style={styles.acceptedFile} />
                    </div>
                    <BitaButton
                      disabled={!filesBacktest?.name}
                      width="160px"
                      onClick={() => {
                        Papa.parse(filesBacktest, {
                          complete: results => {
                            if (
                              results.data.length > 1 &&
                              results.data[0][0].toUpperCase() === 'DATE' &&
                              results.data[0][1].toUpperCase() === 'VALUE'
                            ) {
                              setDataBacktest(results.data);
                            } else {
                              toast.error(
                                'Invalid file type structure. Please upload a file that matches the required format',
                              );
                            }
                          },
                          header: false,
                        });
                      }}
                      primary
                      height="35px"
                    >
                      Upload File
                    </BitaButton>
                    {/* <div className={style.nameFile}>
                      {filesBacktest && `(${filesBacktest.name})`}
                    </div> */}
                  </div>
                  <p className={style.nameFile}>{filesBacktest && `(${filesBacktest?.name})`}</p>
                </div>
              </div>
              <div>{dataBacktest.length > 0 && <BacktestTable />}</div>
            </div>
          </div>

          {data && Object.keys(data ?? {}).length > 0 && (
            <div className={styles.compositionPorfolio}>
              <div className={styles.compositionPorfolioTitle}>Basket Parameters</div>

              <div className={style.contentWidget}>
                <div>Start Parameters</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <BitaInput
                        value={data?.name || ''}
                        type="text"
                        inputStyle={style.nameInput}
                        title="Name"
                        externalTitle="Name"
                        placeholder="Name"
                        justify="flex-start"
                        handleChange={value => {
                          handleChange('name', value);
                        }}
                      />
                      <BitaInput
                        value={data?.description || ''}
                        type="text"
                        inputStyle={style.nameInput}
                        title="Description"
                        externalTitle="Description"
                        placeholder="Description"
                        justify="flex-start"
                        handleChange={value => {
                          handleChange('description', value);
                        }}
                      />
                    </div>
                    <div
                      className={style.contentInput}
                      style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      <div
                        style={
                          (!data?.prefix?.length > 3 && style.needValidation,
                          { pointerEvents: 'none', opacity: '0.5' })
                        }
                      >
                        <BitaInput
                          value={orgPrefix}
                          type="text"
                          inputStyle={style.basketInput}
                          title="Org. Prefix"
                          disabled
                          externalTitle="Org. Prefix"
                        />
                      </div>
                      <BitaInput
                        value={data?.symbol}
                        type="text"
                        inputStyle={
                          !data?.symbol || orgPrefix?.length + data?.symbol?.length > 8
                            ? style.needValidation
                            : style.basketInput
                        }
                        title="Ticker"
                        maxlength={8 - (orgPrefix?.length ?? DUMMIE_PREFIX?.length)}
                        externalTitle="Ticker"
                        placeholder="Ticker"
                        tooltip={
                          8 - (orgPrefix?.length ?? DUMMIE_PREFIX?.length) < 0
                            ? `Character limit exceeded: Maximum of ${8 -
                                (orgPrefix?.length ?? DUMMIE_PREFIX?.length)} characters allowed.`
                            : `Character limit: Maximum of ${8 -
                                (orgPrefix?.length ?? DUMMIE_PREFIX?.length)} characters allowed.`
                        }
                        handleChange={val => {
                          const inputValue = val;
                          const isValid = /^[A-Za-z0-9]+$/.test(inputValue);
                          if (isValid || val === '') handleChange('symbol', val.toUpperCase());
                        }}
                      />
                    </div>
                    <div>
                      <div
                        className={style.contentInput}
                        style={{
                          display: 'flex',
                          gap: '12px',
                          justifyContent: 'flex-start',
                          alignItems: 'baseline',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '18px',
                            width: 'auto',
                          }}
                        >
                          <div>Starting Value</div>
                          <div>
                            <Switcher.Switch
                              className={styles.customRadio}
                              onChange={() =>
                                SetEnableDivisorOrStartingValue({
                                  statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                  statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                                })
                              }
                              checked={enableDivisorOrStartingValue.statusStarting}
                            />
                          </div>
                        </div>
                        {enableDivisorOrStartingValue.statusStarting && (
                          <BitaInput
                            value={data?.starting_value}
                            type="number"
                            inputStyle={
                              !data?.starting_value ? style.needValidation : style.basketInput
                            }
                            title="Start Value"
                            placeholder="Enter Value"
                            lower_limit={0}
                            handleChange={val => {
                              handleChange('starting_value', Number(val.replace(/,/g, '')));
                            }}
                          />
                        )}
                      </div>
                      <div
                        className={style.contentInput}
                        style={{
                          display: 'flex',
                          gap: '12px',
                          justifyContent: 'flex-start',
                          alignItems: 'baseline',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '18px',
                          }}
                        >
                          <div>Divisor</div>
                          <div>
                            <Switcher.Switch
                              className={styles.customRadio}
                              onChange={() =>
                                SetEnableDivisorOrStartingValue({
                                  statusStarting: !enableDivisorOrStartingValue.statusStarting,
                                  statusDivisor: !enableDivisorOrStartingValue.statusDivisor,
                                })
                              }
                              checked={enableDivisorOrStartingValue.statusDivisor}
                            />
                          </div>
                        </div>

                        {enableDivisorOrStartingValue.statusDivisor && (
                          <BitaInput
                            value={data?.divisor}
                            type="number"
                            inputStyle={!data?.divisor ? style.needValidation : style.basketInput}
                            title="Divisor"
                            placeholder="Enter Value"
                            lower_limit={0}
                            handleChange={val => {
                              handleChange('divisor', Number(val.replace(/,/g, '')));
                            }}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      className={style.contentInput}
                      style={{
                        display: 'flex',
                        gap: '12px',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        flexDirection: 'column',
                      }}
                    >
                      <BitaSelect
                        warningIcon
                        selected={data?.calculation_setup ?? ''}
                        value={
                          validateFields('calculation_setup', data?.calculation_setup)
                            ? {
                                value: data?.calculation_setup.replace('_', ' '),
                                id: data?.calculation_setup.replace('_', ' '),
                                name: data?.calculation_setup.replace('_', ' '),
                              }
                            : ''
                        }
                        externalPlaceholder="Calculation Frequency"
                        label="Calculation frequency"
                        data={[
                          { value: 'EOD Only', id: 'EOD' },
                          { value: 'EOD and Intra-day', id: 'EOD_INTRADAY' },
                        ]}
                        handleSelection={value => handleChange('calculation_setup', value?.id)}
                        selectClassname={
                          !validateFields('calculation_setup', data?.calculation_setup) &&
                          style.needValidationSelect
                        }
                      />
                    </div>

                    <div
                      className={style.contentInput}
                      style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                    >
                      {/* <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  value={data?.calculation_open_time}
                  type="time"
                  inputStyle={style.basketInput}
                  title="Calculation Start"
                  externalTitle="Calculation Start"
                  placeholder="Ticker"
                  disabled
                  handleChange={val => {
                    handleChange('calculation_open_time', val);
                  }}
                />
                </div>
                <div style={{ pointerEvents: 'none' }}>
                <BitaInput
                  value={data?.calculation_close_time}
                  type="time"
                  inputStyle={style.basketInput}
                  title="Calculation End"
                  externalTitle="Calculation End"
                  placeholder="Enter Value"
                  disabled
                  handleChange={val => {
                    handleChange('calculation_close_time', val);
                  }}
                />
                </div> */}

                      <BitaSelect
                        warningIcon
                        selected={data?.calculation_zone}
                        value={{
                          value: timezone.find(
                            item => item?.calculationZone === data?.calculation_zone,
                          )?.name,
                          id: data?.calculation_zone,
                          name: data?.calculation_zone,
                        }}
                        label="Calculation Zone"
                        externalPlaceholder="Calculation Zone"
                        data={timezone}
                        handleSelection={value => {
                          handleChange('calculationZone', value?.value);
                        }}
                        selectClassname={
                          !validateFields('calculation_zone', data?.calculation_zone) &&
                          style.needValidationSelect
                        }
                      />
                      {data?.calculation_setup === 'EOD_INTRADAY' && (
                        <BitaSelect
                          warningIcon
                          selected={data?.deployment_setup}
                          value={
                            validateFields('deployment_setup', data?.deployment_setup)
                              ? {
                                  value: data?.deployment_setup,
                                  id: data?.deployment_setup,
                                  name: data?.deployment_setup,
                                }
                              : ''
                          }
                          label="Deployment Setup"
                          externalPlaceholder="Deployment Setup"
                          data={[
                            { value: 'EOD', id: 'EOD' },
                            { value: 'IMMEDIATELY', id: 'IMMEDIATELY' },
                          ]}
                          handleSelection={value => handleChange('deployment_setup', value?.value)}
                          selectClassname={
                            !validateFields('deployment_setup', data?.deployment_setup) &&
                            style.needValidationSelect
                          }
                        />
                      )}
                      <div
                        className={
                          errorCalendar
                            ? `needValidationSelect submitBasketCalendar `
                            : 'submitBasketCalendar'
                        }
                      >
                        <BitaCalendarDate
                          toUpperCaseTitle={false}
                          title="Launch Date"
                          select={value => {
                            if (value !== launchDate) {
                              setErrorCalendar(false);
                            }
                            setLaunchDate(value);
                          }}
                          maxDate={new Date('3000-12-12')}
                          minDate={new Date()}
                          date={launchDate}
                          tooltip="Select today's date or a future date"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={style.contentNoMetadata} style={{ width: '28%' }}>
                    <div className={style.tableNoMetadata}>
                      <div style={{ borderBottom: '1px solid #c7c7c7' }}>
                        Calculation Zone Details
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Timezone: </div> <div>{data?.timezone}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Calculation Start: </div> <div>{data?.calculation_open_time}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Calculation End: </div> <div>{data?.calculation_close_time}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>Start Week Day: </div>{' '}
                        <div>{BUSINESS_DAYS[data?.calculation_start_week_day] || 'Monday'}</div>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>End Week Day: </div>{' '}
                        <div>{BUSINESS_DAYS[data?.calculation_end_week_day] || 'Friday'}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Return type</div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '25px',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <BitaSelect
                      warningIcon
                      selected={data?.calculation_type}
                      value={
                        validateFields('calculation_type', data?.calculation_type)
                          ? {
                              value: data?.calculation_type.replace('_', ' '),
                              id: data?.calculation_type,
                            }
                          : ''
                      }
                      label="Return calculation"
                      data={[
                        { value: 'NET RETURN', id: 'NET_RETURN' },
                        { value: 'TOTAL RETURN', id: 'TOTAL_RETURN' },
                        { value: 'PRICE RETURN', id: 'PRICE_RETURN' },
                      ]}
                      handleSelection={value => handleChange('calculation_type', value?.id)}
                      selectClassname={
                        !validateFields('calculation_type', data?.calculation_type) &&
                        style.needValidationSelect
                      }
                    />

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '5px',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '18px',
                        }}
                      >
                        <div>Annualized Daily Decrement</div>
                        <div>
                          <Switcher.Switch
                            className={styles.customRadio}
                            onChange={() =>
                              setIsActiveAnnualizedYearlyDecrement(
                                !isActiveAnnualizedYearlyDecrement,
                              )
                            }
                            checked={isActiveAnnualizedYearlyDecrement}
                          />
                        </div>
                      </div>

                      <div>
                        {isActiveAnnualizedYearlyDecrement && (
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BitaInput
                              value={data?.percentage_value ?? 0}
                              type="number"
                              upper_limit={100}
                              lower_limit={0}
                              inputStyle={style.basketInput}
                              title="Percentage"
                              externalTitle="Percentage"
                              placeholder="Enter Percentage"
                              tooltip="Please indicate a percentage between 0 and 100"
                              handleChange={val => {
                                handleChange('percentage_value', val);
                              }}
                            />
                            <div style={{ marginTop: '15px', marginLeft: '30px' }}>
                              Day-Count Conversion: 365 Days
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Corporate Actions Methodology</div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'flex-start',
                      pointerEvents: 'none',
                    }}
                  >
                    <div>BITA CORPORATE ACTIONS METHODOLOGY</div>
                    <Switcher.Switch
                      className={styles.customRadio}
                      onChange={() => handleChange('corporate_actions_methodology', 'BITA')}
                      checked={data?.corporate_actions_methodology === 'BITA'}
                    />
                  </div>
                  {/* ALL remember to remove the hardcode from corporate_actions_methodology  */}
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'flex-start',
                      opacity: 0.5,
                      pointerEvents: 'none',
                    }}
                  >
                    <div>CUSTOM METHODOLOGY</div>
                    <Switcher.Switch
                      className={styles.customRadio}
                      onChange={() => handleChange('corporate_actions_methodology', 'CUSTOM')}
                      checked={data?.corporate_actions_methodology !== 'BITA'}
                    />
                  </div>
                  {data?.corporate_actions_methodology &&
                    data?.corporate_actions_methodology !== 'BITA' && (
                      <div style={{ marginTop: '10px' }}>
                        <BitaSelect
                          warningIcon
                          selected={data?.calculation_type}
                          value=""
                          label="Methodology"
                          data={[]}
                          handleSelection={value => handleChange('', value?.id)}
                          style={{ fontSize: 13 }}
                        />
                      </div>
                    )}
                </div>
              </div>
              {data.cash_constituent && (
                <div className={style.contentWidget}>
                  <div>Cash Constituents Configuration</div>
                  <div
                    className={style.contentInputCash}
                    style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                  >
                    <BitaInput
                      value={data?.cash_constituent?.interest_rate_on_cash}
                      type="number"
                      style={{ with: '150px' }}
                      inputStyle={
                        !data?.cash_constituent?.interest_rate_on_cash
                          ? style.needValidationCash
                          : style.basketInputCash
                      }
                      title="Interest Rate on Cash"
                      externalTitle="Interest Rate on Cash"
                      placeholder="Enter Value"
                      lower_limit={0}
                      handleChange={val => {
                        handleChange('cash_constituent', {
                          ...data?.cash_constituent,
                          interest_rate_on_cash: val,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div className={style.contentWidget}>
                <div>Cash Distribution</div>
                <div
                  className={style.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    width="330px"
                    selected={data?.cash_distribution}
                    value={
                      validateFields('cash_distribution', data?.cash_distribution)
                        ? DATA_CASH_DISTRIBUTION.find(item => item.id === data?.cash_distribution)
                        : ''
                    }
                    label="Cash Distribution"
                    externalPlaceholder="Cash Distribution"
                    data={DATA_CASH_DISTRIBUTION}
                    handleSelection={value => handleChange('cash_distribution', value?.id)}
                    selectClassname={
                      !validateFields('cash_distribution', data?.cash_distribution) &&
                      style.needValidationSelect
                    }
                  />

                  <BitaSelect
                    warningIcon
                    width="330px"
                    selected={data?.application_point}
                    label="Application Point"
                    externalPlaceholder="Application Point"
                    value={
                      validateFields('application_point', data?.application_point)
                        ? DATA_APPLICATION_POINT.find(item => item.id === data?.application_point)
                        : ''
                    }
                    data={DATA_APPLICATION_POINT}
                    handleSelection={value => handleChange('application_point', value?.id)}
                    selectClassname={
                      !validateFields('application_point', data?.application_point) &&
                      style.needValidationSelect
                    }
                  />
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Currency</div>
                <div
                  className={style.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.currency}
                    value={
                      validateFields('currency', data?.currency)
                        ? {
                            value: data?.currency,
                            id: data?.currency,
                          }
                        : ''
                    }
                    label="Currency"
                    data={CURRENCIES_ARRAY.map(item => {
                      return { value: item, id: item };
                    })}
                    handleSelection={value => handleChange('currency', value?.id)}
                    selectClassname={
                      !data?.currency || !validateFields('currency', data?.currency)
                        ? style.needValidationSelect
                        : ''
                    }
                  />
                </div>
              </div>

              <div className={style.contentWidget}>
                <div>Dissemination Options</div>
                <div
                  className={style.contentInput}
                  style={{ display: 'flex', gap: '12px', justifyContent: 'flex-start' }}
                >
                  <BitaSelect
                    warningIcon
                    selected={data?.dissemination}
                    value={
                      validateFields('dissemination', data?.dissemination)
                        ? {
                            value: data?.dissemination,
                            id: data?.dissemination,
                          }
                        : {
                            value: 'BITA Only',
                            id: 'BITA Only',
                          }
                    }
                    label="Dissemination"
                    data={[
                      { value: 'BITA Only', id: 'BITA Only' },
                      { value: 'BITA + Bloomberg', id: 'BITA + Bloomberg', disabled: true },
                    ]}
                    handleSelection={value => handleChange('dissemination', value?.id)}
                    style={{ fontSize: 13 }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* Apply Button */}
      <div
        style={{
          width: '100%',
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <BitaButton
          onClick={handleApplyBtn}
          // disabled={errorCalendar || !applyButtonValidations}
          disabled={applyButtonValidations()}
          primary
        >
          Apply
        </BitaButton>
        {data?.meta_reference_data && !validarData(data, validateData) && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '10px',
            }}
          >
            <BiSolidError className="icon-error" />
            <div>Cannot apply until errors are solved</div>
          </div>
        )}
      </div>

      {/* Success Modal */}
      <BitaModal isModalOpen={showModal} setModalState={cleanAllStates}>
        <div style={{ width: ' 600px ', minHeight: '300px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <span onClick={cleanAllStates} style={styleClose}>
              &times;
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={style.modalTextTitle}>Submission successfully completed!</div>
            <div style={{ display: 'flex', margin: '5%', fontSize: '20px' }}>
              <Logo style={{ width: '100px', height: '100px', marginRight: '20px' }} />
              <div>
                <p> Submission ID: {data?.id}</p>
                <p> Submission Status: Transmitted to BITA ACE </p>
                <p> Ticker: {data?.symbol}</p>
                {data?.starting_value && <p> Start Value: {data?.starting_value}</p>}
                {data?.currency && <p> Currency: {data?.currency}</p>}
              </div>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '20px' }}
          >
            <BitaButton primaryWhite onClick={() => history.push('/')} height="35px" width="200px">
              Go to Home
            </BitaButton>
            <BitaButton primaryWhite onClick={cleanAllStates} height="35px" width="200px">
              Submit another basket
            </BitaButton>
          </div>
        </div>
      </BitaModal>
      {/* Confirm Modal */}
      <BitaModal isModalOpen={showModalConfirm} setModalState={() => setShowModalConfirm(false)}>
        <div
          style={{
            width: '850px',
            minHeight: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SUBMIT BASKET
            </h2>
            <span onClick={() => setShowModalConfirm(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px 0', textAlign: 'center' }}>
              {' '}
              Are you sure you want to submit these parameters?{' '}
            </div>

            {/* <div data-simplebar style={{ height: '360px' }}> */}
            <div data-simplebar style={{ height: '460px' }}>
              <div
              // className={tableStyles.tableTitle}
              >
                Basket Data Preview
              </div>
              <div
                // className={tableStyles.tableContainer}
                style={{ width: '100%' }}
              >
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {Object.keys(data ?? {})
                      .filter(key => typeof data[key] === 'string' || typeof data[key] === 'number')
                      ?.map(item => {
                        let label = item;
                        let displayValue = data?.[item];

                        if (item === 'start_week_day' || item === 'calculation_start_week_day') {
                          displayValue = BUSINESS_DAYS[data?.[item]] ?? 'Monday';
                        } else if (item === 'end_week_day' || item === 'calculation_end_week_day') {
                          displayValue = BUSINESS_DAYS[data?.[item]] ?? 'Friday';
                        }

                        if (item === 'description') {
                          displayValue = truncateString(data?.[item]);
                        }

                        if (item === 'calculation_zone') {
                          displayValue = CALCULATION_ZONE_MAPPING[data?.[item]] ?? '';
                        }

                        if (item === 'launch_date') {
                          displayValue = formatDateString(launchDate);
                        }

                        if (item === 'symbol') {
                          label = 'Ticker';
                          displayValue = orgPrefix + displayValue;
                        }

                        return (
                          <div style={{ display: 'flex' }} key={item}>
                            <div>{`${label}`?.replace(/_/g, ' ')} </div>{' '}
                            <div>{`${displayValue}`?.replace(/_/g, ' ')}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  // className={tableStyles.tableTitle}
                  style={{ marginTop: '3%' }}
                >
                  Constituents
                </div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <BasketTable
                    height="100%"
                    titleBasketTable={false}
                    deleteBasketTable={false}
                    buttonsBasketTable={false}
                  />
                  {/* <div className={style.tableNoMetadata}>
                    {Object.keys(data?.metadata?.awf ?? {}).map(item => (
                      <div>{item}</div>
                    ))}
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  const formData = new FormData();
                  const updatedData = {
                    ...data,
                    deployment_setup:
                      data?.calculation_setup !== 'EOD_INTRADAY' || data.deployment_setup === 'EOD'
                        ? 'PREVIOUS_EOD'
                        : data.deployment_setup,
                    metadata: [...data?.meta_reference_data],
                    divisor: data?.divisor,
                    launch_date: formatDateString(launchDate),
                    type: 'basket',
                    // type: type.includes('Bottom') ? 'global' : type.toLowerCase().replace('/', '_'),
                  }; // Add the 'type' property to the data object

                  // delete updatedData.cash_distribution; // Remove the "cash_distribution" key
                  delete updatedData.region;

                  delete updatedData.request_coverage;
                  delete updatedData.full_coverage;
                  delete updatedData.org_prefix;
                  if (data?.calculation_setup !== 'EOD_INTRADAY') {
                    updatedData.calculation_setup = 'EOD';
                  }
                  if (data?.cash_constituent === null) {
                    delete data?.cash_constituent;
                  }

                  if (enableDivisorOrStartingValue.statusStarting && data?.starting_value) {
                    delete updatedData?.meta_reference_data?.divisor;
                  } else if (enableDivisorOrStartingValue.statusDivisor && data?.divisor) {
                    delete updatedData?.meta_reference_data?.divisor;
                  }

                  // Convert the updatedData object to a JSON string
                  const jsonString = JSON.stringify(updatedData);

                  // Append the JSON string to FormData
                  formData.append('data', jsonString);
                  formData.append('org_prefix', orgPrefix);

                  if (filesBacktest) {
                    formData.append('template', filesBacktest);
                  }

                  createBasket(token, formData)
                    .then(rsp => {
                      setShowModalConfirm(false);
                      setShowModal(true);
                      // ALL remember to remove the hardcode from corporate_actions_methodology
                      setData({ ...rsp.data?.data, corporate_actions_methodology: 'BITA' });
                      if (inputBasketRef && inputBasketRef.current) {
                        inputBasketRef.current.value = '';
                      }
                      if (inputBacktestRef && inputBacktestRef.current) {
                        inputBacktestRef.current.value = '';
                      }
                    })
                    .catch(error => {
                      toastInterceptor(error);
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalConfirm(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* SEDOL(S) NOT FOUND Modal */}
      <BitaModal
        isModalOpen={showSedolNotFoundModal}
        setModalState={() => setShowSedolNotFoundModal(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              SEDOL(S) NOT FOUND
            </h2>
            <span onClick={() => setShowSedolNotFoundModal(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Sorry, no information was found for these Sedol(s). Please check your file or contact
              support.
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Sedol(s)</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    {sedolsList.map(item => (
                      <div className={style.requestCoverageEntry}>
                        <div>{item}</div>
                        {/* <div>{Object.values(item)[0]}</div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowSedolNotFoundModal(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Accept
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* showModalRequestCoverage Modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverage}
        setModalState={() => setShowModalRequestCoverage(false)}
      >
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverage(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              We will send to the organization&apos;s e-mail the request for the following
              constituents
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                    <div className={style.tableNoMetadata}>
                      <div className={style.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                          <div>ISIN</div>
                          <div>MIC</div>
                        </>
                      </div>
                      {(data?.meta_reference_data || []).map(item => {
                        if (item?.type !== 'with_coverage') {
                          return (
                            <div className={style.requestCoverageEntry}>
                              <>
                                {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                                <div>{item?.isin}</div>
                                <div>{item?.exchange}</div>
                              </>
                            </div>
                          );
                        }
                        return '';
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  let body;

                  if (data?.identifier_type === 'sedol') {
                    const sedolsArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const sedols = sedolsArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol,
                        isin: coverage?.isin,
                        mic: coverage?.exchange,
                        type: coverage?.type,
                      };
                    });
                    body = {
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: sedols,
                    };
                  }

                  if (data?.identifier_type === 'isin') {
                    const isinArray = data?.meta_reference_data?.filter(
                      item => item?.type !== 'with_coverage',
                    );
                    const isins = isinArray?.map(coverage => {
                      return {
                        sedol: coverage?.sedol,
                        isin: coverage?.isin,
                        mic: coverage?.exchange,
                        type: coverage?.type,
                      };
                    });
                    body = {
                      user_name: user.username,
                      company_name: user.company,
                      request_coverage: isins,
                    };
                  }

                  setShowModalRequestCoverage(false);
                  coverageRequest(token, body)
                    .then(rsp => {
                      if (rsp.data.data.email_sent) {
                        setShowModalRequestCoverageSuccessful(true);
                      } else {
                        toast.error('We had a problem processing your request');
                      }
                      setShowModalRequestCoverage(false);
                    })
                    .catch(error => {
                      toast.error('We had a problem processing your request');
                    });
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Submit
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalRequestCoverage(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* delete constituents modal Modal */}
      <BitaModal isModalOpen={showModalDelete} setModalState={() => setShowModalDelete(false)}>
        <div style={{ width: '600px ', minHeight: '380px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              Delete Constituents
            </h2>
            <span onClick={() => setShowModalDelete(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Do you want to remove these constituents?
            </div>

            <div data-simplebar style={{ height: '360px' }}>
              <div style={{ width: '100%' }}>
                <div>Constituents</div>
                <div className={style.contentNoMetadata} style={{ width: '100%' }}>
                  <div className={style.tableNoMetadata}>
                    <div className={style.requestCoverageEntry}>
                      <>
                        {authOrgPrefix === 'UB' && <div>SEDOL</div>}
                        <div>ISIN</div>
                        <div>MIC</div>
                        {authOrgPrefix !== 'UB' && <div>SEDOL</div>}
                        <div>COMPANY NAME</div>
                      </>
                    </div>
                    {(constituentsDelete || []).map(item => (
                      <div className={style.requestCoverageEntry}>
                        <>
                          {authOrgPrefix === 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.isin ?? '-'}</div>
                          <div>{item?.exchange ?? '-'}</div>
                          {authOrgPrefix !== 'UB' && <div>{item?.sedol ?? '-'}</div>}
                          <div>{item?.company_name ?? '-'}</div>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => {
                  setShowModalDelete(false);
                  const arrayIdentifier = constituentsDelete.map(item => item.isin ?? item.sedol);
                  setData({
                    ...data,
                    meta_reference_data: [
                      ...data?.meta_reference_data?.filter(
                        item => !arrayIdentifier?.includes(item?.isin ?? item?.sedol),
                      ),
                    ],
                  });

                  /*   const deleteArray = (arr, elementos) => {
                    return arr.filter(item => !elementos.includes(item));
                  }; */

                  /* const updateData = () => {
                    setData(prevData => {
                      const newData = { ...prevData };
                      const newMetadata = { ...newData?.meta_reference_data };
                      const newCustomPrice = { ...newData?.custom_prices?.prices };
                      Object.keys(newMetadata).forEach(key => {
                        if (Array.isArray(newMetadata[key])) {
                          newMetadata[key] = deleteArray(newMetadata[key], constituentsDelete);
                        } else {
                          newMetadata[key] = { ...newMetadata[key] };
                          Object.keys(newMetadata[key]).forEach(subKey => {
                            if (constituentsDelete.includes(subKey)) {
                              delete newMetadata[key][subKey];
                              if (data.metadata.exchange[subKey] === 'CASH') {
                                delete newData.cash_constituent;
                              }
                            }
                          });
                        }
                      });
                      if (Object.keys(newCustomPrice).length > 0) {
                        Object.keys(newCustomPrice).forEach(subKey => {
                          if (constituentsDelete.includes(subKey)) {
                            delete newCustomPrice[subKey];
                            if (data.metadata.exchange[subKey] === 'CASH') {
                              delete newData.cash_constituent;
                            }
                          }
                        });

                        newData.custom_prices.prices = newCustomPrice;
                      }
                      newMetadata.divisor = newData.divisor;
                      newData.metadata = newMetadata;
                      const newRequestCoverage = newData?.request_coverage.filter(item => {
                        return !Object.keys(item)?.some(key => constituentsDelete.includes(key));
                      });

                      newData.request_coverage = newRequestCoverage;
                      return newData;
                    });
                  };
                  updateData(); */
                  setConstituentsDelete([]);
                }}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
                autofocus
              >
                Delete
              </BitaButton>
              <BitaButton
                onClick={() => setShowModalDelete(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Cancel
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>

      {/* sucessful coverage request modal */}
      <BitaModal
        isModalOpen={showModalRequestCoverageSuccessful}
        setModalState={() => setShowModalRequestCoverageSuccessful(false)}
      >
        <div style={{ width: '600px ', minHeight: '130px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #eee',
              padding: '10px 0',
            }}
          >
            <Logo style={{ width: '30px', height: '30px', marginRight: '20px' }} />
            <h2
              style={{
                margin: '0',
                padding: '0',
                fontSize: '20px',
                textTransform: 'uppercase',
                textAlign: 'center',
                marginLeft: '-20px',
                width: '100%',
              }}
            >
              REQUEST COVERAGE
            </h2>
            <span onClick={() => setShowModalRequestCoverageSuccessful(false)} style={styleClose}>
              &times;
            </span>
          </div>
          <div>
            <div style={{ margin: '20px', textAlign: 'center' }}>
              Your request is being processed, we will contact you shortly.
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <BitaButton
                onClick={() => setShowModalRequestCoverageSuccessful(false)}
                width="150px"
                height="30px"
                style={{ margin: '5px' }}
                primary
              >
                Close
              </BitaButton>
            </div>
          </div>
        </div>
      </BitaModal>
    </div>
  );
};

export default SubmitBasket;
