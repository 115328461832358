import React, { useState } from 'react';
import styles from './IndexBuilderModal.module.scss';

const createObjString = (obj, initialString, endString) => {
  if (obj && obj.value) {
    return initialString + obj.value + endString;
  }
  if (obj) {
    return initialString + obj + endString;
  }

  return initialString;
};

const valueMapping = (obj, keyRender) => {
  // eslint-disable-next-line
  if (typeof obj === 'object') {
    const returnString = [];
    let rec = '';
    // obj[0].map(res => returnString.push(`[${res.selected}, ${res.value}%]`));
    // Object.entires(obj).map(([key, value]) => returnString.push(`[${obj[selected]}, ${res.value}%]`)

    switch (keyRender) {
      case 'Reconstitution' || 'Rebalancing': {
        const objArray = Object.entries(obj);
        objArray.map(
          // eslint-disable-next-line no-return-assign
          ([key, value], i) =>
            (rec = createObjString(
              `${key}: ${value.value || value}`,
              rec,
              objArray.length - 1 > i ? ', ' : ' ',
            )),
        );
        returnString.push(rec);
        return returnString;
      }

      case 'Return calculation':
        return `[${obj?.return_calculation}]`;

      case 'Number of constituents':
        if (obj.ranking_type?.value === 'Rank Between') {
          return `[${obj?.number_of_constituents?.value}] ${obj.rank_by?.value || ''} ${
            obj.ranking_type?.value
              ? `, ${obj.ranking_type?.value}: ${obj['Between Left Value'] || ' '} - ${obj[
                  'Between Right Value'
                ] || ' '}`
              : ''
          } `;
        }
        return `[${obj?.number_of_constituents?.value}] ${obj.rank_by?.value || ''} ${
          obj.ranking_type?.value ? `, ${obj.ranking_type?.value}: ${obj.index_len || ' '}` : ''
        } `;

      case 'Weighting mechanism':
        return `[${obj?.weighting_mechanism}] ${obj['Weight by']?.value || ''}  ${obj.weight_by2
          ?.value || ''} `;

      case 'Exit Buffer':
        return obj.exit_buffer ? `${obj.exit_buffer}%` : ' ';

      case 'Caps':
        // eslint-disable-next-line array-callback-return
        obj.Caps.map((field, index) => {
          returnString.push(
            `${field?.Selected?.value} : ${
              field?.addibleinput ? `${field?.addibleinput} %` : ' '
            }  ${index < obj.Caps.length - 1 ? ' - ' : ' '}`,
          );
        });
        return returnString.map(string => `${string}`);

      case 'Floors':
        // eslint-disable-next-line array-callback-return
        obj.Floors.map((field, index) => {
          const auxFloors = `${field?.Selected?.value} : ${
            field?.['Floors Value'] ? `${field?.['Floors Value']} %` : ' '
          }  ${index < obj.Floors.length - 1 ? ' - ' : ' '}`;
          returnString.push(auxFloors);
        });
        return returnString.map(string => `${string}`);

      case 'Fast Entry':
        return `[${obj?.fast_entry}] ${obj?.['Buffer options']?.value || ' '} 
        ${obj?.['Buffer Percentage'] ? `:${obj?.['Buffer Percentage']} %` : ' '} ${
          obj?.['Buffer Position'] ? `:${obj?.['Buffer Position']}` : ' '
        } `;

      case 'Fast exit':
        return `[${obj?.fast_exit}] ${obj?.Criterion?.value || ' '} 
        ${Object.values(obj)?.[2]?.value ? `- ${Object.values(obj)?.[2]?.value},` : ' '} ${obj
          ?.operators?.value || ' '} 
        ${obj?.operators_value || ' '} `;

      case 'Cash Component':
        return `${obj?.Cash ? `${obj?.Cash}%` : ''}`;

      case 'Deletion & replacement rules':
        return `${
          obj?.deletion_replacement_rules === 'Same Weight' ||
          obj?.deletion_replacement_rules === 'Perform Extraordinary Reconstitution'
            ? '[Simultaneous to Event]'
            : ''
        } ${obj?.deletion_replacement_rules || ''}`;

      case 'Global Minimum Size Reference':
        return `${obj.universe.value}, percentile: ${obj.percentile}, multiplied: ${obj.multiplied}`;

      case 'Securities Considerations':
        return (
          <div>
            {obj.adrs || ''}
            <div>
              {obj.ipo_rule ? `[${obj.ipo_rule}] ` : ' '} {obj.Period?.value || ''}{' '}
            </div>
            <div>
              {obj.share_class_rule ? `[${obj.share_class_rule}] ` : ' '} {obj.Rule?.value || ''}
            </div>
            <div>
              {obj.minimum_foreign_ownership_limit
                ? `[${obj.minimum_foreign_ownership_limit}] `
                : ' '}{' '}
              {obj['Only include instruments whose Foreign Ownership Limit is more than']
                ? `${obj['Only include instruments whose Foreign Ownership Limit is more than']}%`
                : ''}
            </div>
            <div>
              {obj.minimum_foreign_room ? `[${obj.minimum_foreign_room}] ` : ' '}{' '}
              {obj['Only include instruments whose Foreign Room is more than']
                ? `${obj['Only include instruments whose Foreign Room is more than']}%`
                : ''}
            </div>
            <div>
              {obj.minimum_free_float_available ? `[${obj.minimum_free_float_available}] ` : ' '}{' '}
              {obj[
                'Only include instruments whose Free Float Available to Foreign Investors is more than'
              ]
                ? `${obj['Only include instruments whose Free Float Available to Foreign Investors is more than']}%`
                : ''}
            </div>
            <div>
              {obj.minimum_free_float_percentage ? `[${obj.minimum_free_float_percentage}] ` : ' '}
              {obj['Only include instruments whose Free Float Market Cap is more than']
                ? `${obj['Only include instruments whose Free Float Market Cap is more than']}%`
                : ''}
            </div>
          </div>
        );

      default:
        Object.values(obj).map(field => {
          if (typeof field === 'object') {
            if (Array.isArray(field)) {
              field.map(arr => {
                const inputValue = arr.addibleinput ? arr.addibleinput : arr['Floors Value'];
                returnString.push(`${arr.Selected.value} : ${inputValue} `);
                return arr;
              });
            } else {
              returnString.push(`${field.value} `);
            }
          } else {
            returnString.push(`${field} `);
          }
          return field;
        });
        return returnString;
    }
  } else {
    return Object.values(obj).map(string => `${string}`);
  }
};

function TabsComponent({ data }) {
  const [activeTab, setActiveTab] = useState(0);
  const dataFiltering = (dataValues, selector) =>
    dataValues &&
    Object.entries(dataValues)
      .filter(([key]) => key.includes(selector))
      .map(dataValue => {
        return { [dataValue[0]]: dataValue[1] };
      });
  const tabs = [
    {
      title: 'General Rules',
      content: dataFiltering(data, 'General Rules'),
      filter: 'General Rules',
      rows: [
        { name: 'Number of Constituents', key: 'Number of constituents' },
        { name: 'Currency', key: 'Currency' },
        { name: 'Return Calculation', key: 'Return calculation' },
        { name: 'Weighting Mechanism', key: 'Weighting mechanism' },
      ],
    },
    {
      title:
        data?.['Type Methodology'] === 'Rebalancing and Reconstitution'
          ? 'Caps, Floors & Buffer'
          : 'Rebalancing & Reconstitution',
      content:
        data?.['Type Methodology'] === 'Rebalancing and Reconstitution'
          ? dataFiltering(data, 'Caps, Floors & Buffer')
          : dataFiltering(data, 'Rebalancing & Reconstitution'),
      filter:
        data?.['Type Methodology'] === 'Rebalancing and Reconstitution'
          ? 'Caps, Floors & Buffer'
          : 'Rebalancing & Reconstitution',
      rows:
        data?.['Type Methodology'] === 'Rebalancing and Reconstitution'
          ? [
              { name: 'Caps', key: 'Caps' },
              { name: 'Floors', key: 'Floors' },
              { name: 'Exit Buffer', key: 'Exit Buffer' },
            ]
          : [
              { name: 'Caps', key: 'Caps' },
              { name: 'Floors', key: 'Floors' },
              { name: 'Rebalancing', key: 'Rebalancing' },
              { name: 'Reconstitution', key: 'Reconstitution' },
              { name: 'Exit Buffer', key: 'Exit Buffer' },
            ],
    },
    {
      title: 'Fast Entry & Exit',
      content: dataFiltering(data, 'Fast Entry & Exit'),
      filter: 'Fast Entry & Exit',
      rows: [
        { name: 'Fast Entry', key: 'Fast Entry' },
        { name: 'Fast Exit', key: 'Fast exit' },
      ],
    },
    {
      title: 'Additional Rules',
      content: dataFiltering(data, 'Additional Rules'),
      filter: 'Additional Rules',
      rows: [
        { name: 'Cash Component', key: 'Cash Component' },
        { name: 'Deletion Replacement Rules', key: 'Deletion & replacement rules' },
        { name: 'Securities Considerations', key: 'Securities Considerations' },
        { name: 'Global Minimum Size Reference', key: 'Global Minimum Size Reference' },
      ],
    },
  ];

  return (
    <>
      <div className={styles.tabsButtonContainer}>
        {tabs.map((tabTitle, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          // eslint-disable-next-line
          <div
            className={`${styles.tabTitleButton} ${
              index === activeTab ? styles.tabTitleButtonActive : styles.tabTitleButtonInactive
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tabTitle.title}
          </div>
        ))}
      </div>
      <div className={styles.listContainer}>
        <span>
          <ul className={styles.tableList}>
            {tabs.map(
              (tab, index) =>
                index === activeTab &&
                tab.rows.map(row => (
                  <li className={styles.tableElement}>
                    <div className={styles.tableFirstRow}>{row.name}</div>
                    <div className={styles.tableSecondRow}>
                      {tab.content && tab.content.length >= 1
                        ? Object.entries(tab.content).map(([, value]) => {
                            if (
                              value[tab.filter][row.key] &&
                              value[tab.filter][row.key].Reconstitution === 'Same as Rebalancing'
                            ) {
                              // eslint-disable-next-line no-unused-expressions
                              return value[tab.filter][row.key].Reconstitution;
                            }
                            if (
                              value[tab.filter][row.key] &&
                              value[tab.filter][row.key].Reconstitution
                            ) {
                              return `[${value[tab.filter][row.key].Reconstitution}] Ex-Date: ${
                                value[tab.filter][row.key]?.['Ex-Date']?.value
                              }, ${value[tab.filter][row.key]?.Day?.value} ${value[tab.filter][
                                row.key
                              ]?.Months?.value || ''}. Determination: ${
                                value[tab.filter][row.key]?.['Determination date']?.value
                              } days`;
                            }
                            if (
                              value[tab.filter][row.key] &&
                              value[tab.filter][row.key].Rebalancing
                            ) {
                              return `[${value[tab.filter][row.key].Rebalancing}] Ex-Date: ${
                                value[tab.filter][row.key]?.['Ex-Date']?.value
                              }, ${value[tab.filter][row.key]?.Day?.value} ${value[tab.filter][
                                row.key
                              ]?.Months?.value || ''}. Determination: ${
                                value[tab.filter][row.key]?.['Determination date']?.value
                              } days before ex-date `;
                            }
                            return value[tab.filter][row.key]
                              ? valueMapping(value[tab.filter][row.key], row.key)
                              : 'No parameter was chosen';
                          })
                        : 'No parameter was chosen'}
                    </div>
                  </li>
                )),
            )}
          </ul>
        </span>
      </div>
    </>
  );
}

export default TabsComponent;
