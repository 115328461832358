/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import { toast } from 'react-toastify';
import _ from 'lodash';
import * as SumaryActions from '../../../../state/actions/summary-actions';
import { setApiData } from '../../../../state/actions/api-data-actions';
import { data_attribute_format } from '../../../../utils/utils';
import {
  nameCountryMethodologyCap,
  nameSectorMethodologyCap,
} from '../../../../constants/methodologies-constants';

const saveMethodology = (
  name,
  description,
  summaryData,
  token,
  routeChange,
  clear,
  dispatch,
  allMethodologies,
  userName,
) => {
  try {
    const operations_name = [];
    const constraints_names = [];
    let rank_by = '';
    let rank_from = '';
    let predefined_target = true;
    let rank_by_ascending = '';
    let is_ascending = '';
    let target_range = '';
    let upper_buffer = '';
    let lower_buffer = '';
    let classification_by = '';
    let index_len = '';
    let currency = '';
    let base_currency = '';
    let tax = '';
    let type = '';
    let weight_by = '';
    const weight_limits = {};
    const cap_params = {};
    let cap = '';
    let floor = '';
    let gmsr_params = {};
    let group_name = '';
    const group_name_ucits = '';
    const rebalancing = {};
    let reconstitution = {};
    // Nuevo
    const fast_positions = [];
    const checking_factors = [];
    const fast_sorting_conditions = [];
    let cash = '';
    let deletion_rule = '';
    let adrs = true;
    let share_class_rule = '';
    let minimum_free_float = '';
    let equalWeight = false;
    const objective_function = '';
    let fast_entry_position = '';
    let fast_entry_percentage = '';
    let exit_buffer_percentage = '';
    const exit_buffer_position = '';
    const fundamentals_to_filter = {};
    const fundamentals_conditions = {};
    const individual_cap_limit = '';
    const combination_threshold = '';
    const combined_cap_limit = '';
    let liquidity_column = '';
    const adtv_threshold = '';
    const investment_threshold = '';
    let highest_rank_consideration = false;

    const addOperationName = operation => {
      if (!operations_name.find(op => op === operation)) {
        operations_name.push(operation);
      }
    };

    const validateMultipleCapFloor = (capObject, floorObject) => {
      const options = ['Security', 'Country', 'Sector', 'Issuer'];
      let result = 0;
      for (let i = 0; options.length >= i; i += 1) {
        if (capObject[options[i]] && floorObject[options[i]]) result += 1;
        if (capObject[options[i]] && !floorObject[options[i]]) result += 1;
        if (!capObject[options[i]] && floorObject[options[i]]) result += 1;
      }
      return result > 1;
    };

    const setSimpleCapFloor = (capObject, floorObject) => {
      const options = ['Security', 'Country', 'Sector', 'Issuer'];
      for (let i = 0; options.length >= i; i += 1) {
        if (capObject[options[i]] || floorObject[options[i]]) {
          group_name = options[i].toLowerCase();
          cap = capObject[options[i]] ? parseFloat((capObject[options[i]] || 100) / 100) : 1;
          floor = floorObject[options[i]] ? parseFloat((floorObject[options[i]] || 0) / 100) : 0;
          i = options.length + 1;
        }
      }
    };

    const generateFastEntryExitBody = (fastType, dataObject) => {
      const fastArray = [];
      const checkingArray = [];
      const conditionsArray = [];
      if (dataObject['Buffer Percentage'] || dataObject['Buffer Position']) {
        fast_entry_percentage = dataObject['Buffer Percentage']
          ? Number(dataObject['Buffer Percentage'].replace(/,/g, '')) / 100
          : '';
        fast_entry_position = dataObject['Buffer Position']
          ? Number(dataObject['Buffer Position'].replace(/,/g, ''))
          : '';
      }
      if (dataObject['Criterion']) {
        const dataSplit = dataObject['Criterion']['value'].split(' ');
        let criterion = dataSplit.length === 1 ? dataSplit[0] : dataSplit[1];
        if (criterion.endsWith('s')) {
          criterion = criterion.slice(0, -1);
        }
        const searchCriterion =
          criterion.charAt(0).toUpperCase() + criterion.substring(1).toLowerCase();
        checkingArray.push({
          criterion: dataObject[`${searchCriterion}`]['value'],
          periodicity: dataObject[`fast_${fastType}`] ? dataObject[`fast_${fastType}`] : '',
        });
        if (dataObject['operators'] && dataObject['operators']['id']) {
          const operator = dataObject['operators']['id'];
          conditionsArray.push(operator !== '0');
          const inputUnit = fastType === 'exit' ? 'value' : 'percentage';
          fastArray.push({
            [`${fastType}_${inputUnit}`]:
              Number(
                dataObject['operators_value']
                  ? dataObject['operators_value'].replace(/,/g, '')
                  : operator !== '0'
                  ? '100'
                  : '0',
              ) / (fastType === 'exit' ? 1 : 100),
          });
        }
        fast_positions.push(fastArray);
        checking_factors.push(checkingArray);
        fast_sorting_conditions.push(conditionsArray);
      }
    };

    if (summaryData) {
      // Get data for General Rules
      if (summaryData['General Rules']) {
        const general_rules = summaryData['General Rules'];
        if (general_rules['Number of constituents']) {
          operations_name.push('filter_top_bottom');
          const number_of_consituents = general_rules['Number of constituents'];
          const constituentType = _.get(
            number_of_consituents,
            ['number_of_constituents', 'value'],
            'None',
          );
          rank_by = number_of_consituents['rank_by']
            ? constituentType.toLowerCase() === 'factor'
              ? data_attribute_format(number_of_consituents['rank_by']['value'])
              : number_of_consituents['rank_by']['value']
            : '';
          const ranking_type = number_of_consituents['ranking_type']
            ? number_of_consituents['ranking_type']['value']
            : '';
          if (ranking_type === 'Rank Between') {
            const firstValue = _.get(number_of_consituents, ['Between Left Value'], '0');
            const secondValue = _.get(number_of_consituents, ['Between Right Value'], '0');
            rank_by_ascending = '';
            rank_from = parseInt(firstValue.replace(',', ''), 10);
            index_len =
              parseInt(secondValue.replace(',', ''), 10) -
              parseInt(firstValue.replace(',', ''), 10);
          } else if (constituentType === 'Accumulation') {
            operations_name.push('filter_ranked_buffer');
            is_ascending = !number_of_consituents.ranking_type.value;
            classification_by = data_attribute_format(
              number_of_consituents.group_by.value === 'BITA Assigned Country'
                ? 'bita_country'
                : number_of_consituents.group_by.value?.replace('Country of ', ''),
            );
            predefined_target = !number_of_consituents.value_default_nro_constituents;
            if (
              number_of_consituents.value_default_nro_constituents ||
              (number_of_consituents.number_of_constituents.value === 'Accumulation' &&
                (number_of_consituents.group_by.value === 'GICS Sector' ||
                  number_of_consituents.group_by.value === 'GICS Industry'))
            ) {
              // eslint-disable-next-line prefer-destructuring
              target_range = Number(number_of_consituents.target_range);
              // eslint-disable-next-line prefer-destructuring
              upper_buffer = Number(number_of_consituents.upper_buffer);
              // eslint-disable-next-line prefer-destructuring
              lower_buffer = Number(number_of_consituents.lower_buffer);
            }
          } else {
            rank_by_ascending = ranking_type === 'Bottom';
            index_len = parseInt(number_of_consituents['index_len']?.replace(',', ''), 10) || 0;
          }
        }
        if (general_rules['Currency']) {
          const currencyG = general_rules['Currency'];
          if (currencyG) {
            currency = currencyG['Currency']['value'] || 'USD';
            base_currency =
              currencyG['Currency']['value'] !== 'Use Local Currency'
                ? currencyG['Currency']['value']
                : 'local_currency';
          }
        }
        if (general_rules['Return calculation']) {
          const return_calculation = general_rules['Return calculation'];
          type = return_calculation['return_calculation'].toLowerCase().replace(/\s/g, '_') || '';
          tax = general_rules['tax'] || '';
        }
        if (general_rules['Weighting mechanism']) {
          const weighting_mechanism = general_rules['Weighting mechanism'];
          if (weighting_mechanism && weighting_mechanism['Weight by']) {
            equalWeight = weighting_mechanism['Weight by']['id'] === '0';
            if (equalWeight) {
              weight_by = weighting_mechanism['Weight by']['value'];
              operations_name.push('equal_weight');
            } else {
              const weightType = weighting_mechanism['Weight by']['value'];
              const weightValue = weighting_mechanism['weight_by2']
                ? weighting_mechanism['weight_by2']['value']
                : weighting_mechanism['Weight by']
                ? weighting_mechanism['Weight by']['value']
                : '';
              operations_name.push('uncapped_weight');
              weight_by =
                weightType.toLowerCase() === 'factor exposure'
                  ? data_attribute_format(weightValue)
                  : weightValue;
            }
          }
        }
      }

      const setMinimunAdditionalRule = (additionalRule, ruleType) => {
        let fundamental = '';
        const operator = '<';

        switch (ruleType) {
          case 'Only include instruments whose Free Float Market Cap is more than':
            fundamental = 'Free Float Percent - Instrument';
            break;
          case 'Only include instruments whose Foreign Room is more than':
            fundamental = 'Foreign Room';
            break;
          case 'Only include instruments whose Free Float Available to Foreign Investors is more than':
            fundamental = 'Free Float Available to Foreign Investors';
            break;
          case 'Only include instruments whose Foreign Ownership Limit is more than':
            fundamental = 'Foreign Ownership Limit';
            break;
          default:
            break;
        }
        addOperationName('filter_fundamentals');
        minimum_free_float = Number(additionalRule[ruleType].replace(/,/g, '')) / 100;
        fundamentals_to_filter[fundamental] = minimum_free_float;
        fundamentals_conditions[fundamental] = operator;
      };
      // Get data for Rebalancing & reconstitution
      if (
        summaryData['Rebalancing & reconstitution'] ||
        summaryData['Rebalancing & Reconstitution'] ||
        summaryData['Caps, Floors & Buffer']
      ) {
        const rebalancing_reconstitution =
          summaryData['Rebalancing & reconstitution'] ||
          summaryData['Rebalancing & Reconstitution'] ||
          summaryData['Caps, Floors & Buffer'];

        const constraints = [];
        const multiCaps = false;
        let multiFloor = false;
        if (rebalancing_reconstitution['Caps']) {
          const IssuerCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Issuer',
          );
          const CountryCap = rebalancing_reconstitution['Caps'].Caps.filter(
            row => row.Selected.value === 'Country',
          );
          const SecurityCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Security',
          );
          const SectorCap = rebalancing_reconstitution['Caps'].Caps.filter(
            row => row.Selected.value === 'Sector',
          );
          const LiquidityCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Liquidity',
          );
          const UCITSCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Individual Securities (UCITS group)',
          );
          const UCITSIssuerCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Individual Issuer (UCITS group)',
          );
          const IndustryCap = rebalancing_reconstitution['Caps'].Caps.find(
            row => row.Selected.value === 'Industry',
          );
          if (!operations_name.find(item => item === 'sequencial_capping')) {
            operations_name.push('sequencial_capping');
          }
          if (LiquidityCap) {
            cap_params.liquidity_cap = {
              column: LiquidityCap?.liquidity_column.value,
              portfolio_size: Number(LiquidityCap?.investment_threshold?.replace(/,/g, '') ?? 0),
              allowed_volume: parseFloat((LiquidityCap?.adtv_threshold ?? 0) / 100),
            };
            liquidity_column = LiquidityCap?.liquidity_column.value;
          }
          if (IssuerCap) {
            cap_params.issuer_cap = parseFloat((IssuerCap.addibleinput || 0) / 100);
          }
          if (UCITSCap) {
            cap_params.ucits = {
              individual_cap: parseFloat((UCITSCap.combination_threshold || 0) / 100),
              group_cap: parseFloat((UCITSCap.combined_cap_limit || 0) / 100),
            };
          }
          if (UCITSIssuerCap) {
            cap_params.ucits_issuer = {
              individual_cap: parseFloat((UCITSIssuerCap.combination_threshold || 0) / 100),
              group_cap: parseFloat((UCITSIssuerCap.combined_cap_limit || 0) / 100),
            };
          }
          if (IndustryCap?.addibleinput) {
            cap_params.gics_industry = parseFloat((IndustryCap.addibleinput || 0) / 100);
          }
          if (SecurityCap?.addibleinput)
            cap_params.regular_cap = parseFloat((SecurityCap.addibleinput || 0) / 100);
          if (SectorCap?.length > 0) {
            const sectorSpecific = SectorCap.find(item => item?.SectorType?.value === 'Specific');
            const sectorRegular = SectorCap.find(item => item?.SectorType?.value === 'Generic');
            const objectSectors = (sectorSpecific?.sectorCap ?? []).reduce((acc, item) => {
              acc[item.value] = parseFloat((item.cap || 0) / 100);
              return acc;
            }, {});
            cap_params.gics_sector = {
              class: nameSectorMethodologyCap[SectorCap[0]?.SelectedSector?.value],
            };
            if (sectorRegular?.addibleinput) {
              cap_params.gics_sector.regular_cap = parseFloat(
                (sectorRegular?.addibleinput || 0) / 100,
              );
            }
            if (sectorSpecific?.sectorCap?.length > 0) {
              cap_params.gics_sector.specific_cap = objectSectors;
            }
          }
          if (CountryCap?.length > 0) {
            const countrySpecific = CountryCap.find(item => item?.CapType?.value === 'Specific');
            const countryRegular = CountryCap.find(item => item?.CapType?.value === 'Generic');
            const objectCountry = (countrySpecific?.countryCap ?? []).reduce((acc, item) => {
              acc[item.value] = parseFloat((item.cap || 0) / 100);
              return acc;
            }, {});
            cap_params.country = {
              class: nameCountryMethodologyCap[CountryCap[0]?.SelectedCountry?.value],
            };

            if (countryRegular?.addibleinput) {
              cap_params.country.regular_cap = parseFloat((countryRegular.addibleinput || 0) / 100);
            }
            if (countrySpecific?.countryCap?.length > 0) {
              cap_params.country.specific_cap = objectCountry;
            }
          }
        }
        if (rebalancing_reconstitution['Floors']) {
          const floorCountry = rebalancing_reconstitution['Floors']?.Floors?.find(
            item => item.Selected.value === 'Country',
          );
          const floorSector = rebalancing_reconstitution['Floors']?.Floors?.find(
            item => item.Selected.value === 'Sector',
          );
          const SecurityFloor = rebalancing_reconstitution['Floors'].Floors.find(
            row => row.Selected.value === 'Security',
          );
          if (floorCountry) {
            cap_params.country_floor = {
              class: nameCountryMethodologyCap[floorCountry?.floorCountry?.value],
              regular_floor: parseFloat((floorCountry?.['Floors Value'] || 0) / 100),
            };
          }
          if (floorSector) {
            cap_params.gics_sector_floor = {
              class: nameSectorMethodologyCap[floorSector?.floorSector?.value],
              regular_floor: parseFloat((floorSector?.['Floors Value'] || 0) / 100),
            };
          }
          if (SecurityFloor?.['Floors Value']) {
            cap_params.floor_cap = parseFloat((SecurityFloor?.['Floors Value'] || 0) / 100);
          }
          if (rebalancing_reconstitution['Floors']?.Floors?.length > 1) {
            multiFloor = true;
          }
          const prefloors = rebalancing_reconstitution['Floors'].Floors.map(row => {
            constraints.push(row.Selected.value.toLowerCase());
            return {
              [row.Selected.value]: row['Floors Value'],
            };
          });
          floor = prefloors.reduce((obj, item) => {
            return Object.assign(obj, item);
          }, {});
          if (!operations_name.find(item => item === 'sequencial_capping')) {
            operations_name.push('sequencial_capping');
          }
        }
        if (
          rebalancing_reconstitution['Rebalancing'] &&
          rebalancing_reconstitution['Reconstitution']
        ) {
          if (rebalancing_reconstitution['Rebalancing']['Rebalancing'] === 'Monthly') {
            rebalancing_reconstitution['Rebalancing'].Months = {
              id: 1,
              value:
                'January-February-March-April-May-June-July-August-September-October-November-December',
            };
          }
          const dataRebalancing = rebalancing_reconstitution['Rebalancing'];
          Object.keys(dataRebalancing).forEach(element => {
            rebalancing[
              element.replace(' ', '_').replace('Determination', 'Proforma')
            ] = dataRebalancing[element]
              ? dataRebalancing[element]['value'] ?? dataRebalancing[element]
              : '';
          });
        }
        if (
          rebalancing_reconstitution['Reconstitution'] &&
          rebalancing_reconstitution['Reconstitution']['Reconstitution'] !== 'Same as Rebalancing'
        ) {
          if (rebalancing_reconstitution['Reconstitution']['Reconstitution'] === 'Monthly') {
            rebalancing_reconstitution['Reconstitution'].Months = {
              id: 1,
              value:
                'January-February-March-April-May-June-July-August-September-October-November-December',
            };
          }
          const dataReconstitution = rebalancing_reconstitution['Reconstitution'];
          Object.keys(dataReconstitution).forEach(element => {
            reconstitution[
              element.replace(' ', '_').replace('Determination', 'Proforma')
            ] = dataReconstitution[element]
              ? dataReconstitution[element]['value'] ?? dataReconstitution[element]
              : '';
          });
        } else {
          reconstitution = { ...rebalancing };
          reconstitution.Reconstitution = rebalancing.Rebalancing;
          delete reconstitution.Rebalancing;
        }

        if (
          rebalancing_reconstitution['Exit Buffer'] &&
          rebalancing_reconstitution['Exit Buffer']['exit_buffer']
        ) {
          addOperationName('filter_buffer_fast');
          exit_buffer_percentage =
            Number(rebalancing_reconstitution['Exit Buffer']['exit_buffer'].replace(/,/g, '')) /
            100;
        }
      }
      // Get data for Fast Exit & Entry
      if (summaryData['Fast Entry & Exit']) {
        const fastEntryExit = summaryData['Fast Entry & Exit'];
        operations_name.push('fast_entry_exit');

        if (fastEntryExit['Fast Entry']) {
          const fastEntry = fastEntryExit['Fast Entry'];
          if (fastEntry.fast_entry && fastEntry.fast_entry === 'At Reconstitution') {
            addOperationName('filter_buffer_fast');
          }
          generateFastEntryExitBody('entry', fastEntry);
        }
        if (fastEntryExit['Fast exit']) {
          const fastExit = fastEntryExit['Fast exit'];
          generateFastEntryExitBody('exit', fastExit);
        }
      }
      // Get data for Additional Rules
      if (summaryData['Additional Rules']) {
        const aditional_rules = summaryData['Additional Rules'];
        if (aditional_rules['Cash Component']) {
          cash = Number(aditional_rules['Cash Component']['Cash'].replace(/,/g, '')) / 100 || '';
        }
        if (aditional_rules['Deletion & replacement rules']) {
          const deletion_rules = aditional_rules['Deletion & replacement rules'];
          deletion_rule = deletion_rules['deletion_replacement_rules'];
        }
        if (aditional_rules['Securities Considerations']) {
          const securities = aditional_rules['Securities Considerations'];
          adrs = !!securities.adrs;
          if (adrs) {
            addOperationName('filter_fundamentals');
            fundamentals_to_filter['dr_flag'] = 1;
            fundamentals_conditions['dr_flag'] = '==';
          }
          if (securities.ipo_rule && securities['Period']?.['value']) {
            addOperationName('filter_fundamentals');
            fundamentals_to_filter['ipo_date'] = securities['Period']['value'];
            fundamentals_conditions['ipo_date'] = '<';
          }
          if (securities.share_class_rule) {
            if (securities['Rule'].value === 'Highest Ranked') {
              highest_rank_consideration = true;
            }
            switch (securities['Rule']['id']) {
              case '0':
                operations_name.push('highest_ranked_share_class');
                break;
              case '1':
                operations_name.push('merge_share_classes');
                break;
              default:
                break;
            }
            share_class_rule = securities['Rule'] ? securities['Rule']['value'] : '';
          }
          if (
            securities.minimum_foreign_ownership_limit &&
            _.has(securities, 'Only include instruments whose Foreign Ownership Limit is more than')
          ) {
            setMinimunAdditionalRule(
              securities,
              'Only include instruments whose Foreign Ownership Limit is more than',
            );
          }
          if (
            securities.minimum_free_float_percentage &&
            _.has(securities, 'Only include instruments whose Free Float Market Cap is more than')
          ) {
            setMinimunAdditionalRule(
              securities,
              'Only include instruments whose Free Float Market Cap is more than',
            );
          }
          if (
            securities.minimum_foreign_room &&
            _.has(securities, 'Only include instruments whose Foreign Room is more than')
          ) {
            setMinimunAdditionalRule(
              securities,
              'Only include instruments whose Foreign Room is more than',
            );
          }

          if (
            securities.minimum_free_float_available &&
            _.has(
              securities,
              'Only include instruments whose Free Float Available to Foreign Investors is more than',
            )
          ) {
            setMinimunAdditionalRule(
              securities,
              'Only include instruments whose Free Float Available to Foreign Investors is more than',
            );
          }
        }
        if (aditional_rules['Global Minimum Size Reference']) {
          gmsr_params = {
            universe_id: aditional_rules['Global Minimum Size Reference']?.universe?.id,
            percentile: Number(aditional_rules['Global Minimum Size Reference']?.percentile) / 100,
            multiplicative_factor: Number(
              aditional_rules['Global Minimum Size Reference']?.multiplied,
            ),
          };
          operations_name.push('global_minimum_size_reference');
        }
      }
      // name and description are required
      if (name === '') {
        toast.error('Methodology name is required');
      } else {
        // set format for api
        const data = {
          strategy_name: 'general_strategy',
          calculate: true,
          parameters: {
            operations: {
              constitute: {
                operation_name: 'constitute',
              },
              run_index: {
                operation_name: 'run_index',
              },
              normalize_index: {
                operation_name: 'normalize_index',
              },
            },
            data: {
              constraints_names,
              operations_name,
              fast_entry_position,
              fast_entry_percentage,
              exit_buffer_percentage,
              exit_buffer_position,
              objective_function,
              fundamentals_conditions,
              deletion_rule: deletion_rule.toLowerCase().replace(/\s/g, '_') || '',
              base_currency,
              cash,
              group_name,
              group_name_ucits,
              cap,
              floor,
              cap_params,
              gmsr_params,
              weight_limits,
              individual_cap_limit,
              combined_cap_limit,
              combination_threshold,
              adtv_threshold,
              highest_rank_consideration,
              liquidity_column,
              investment_threshold,
              weight_by,
              tax,
              rank_by,
              rank_by_ascending,
              is_ascending,
              predefined_target,
              target_range,
              upper_buffer,
              lower_buffer,
              classification_by,
              index_len,
              proforma_dates: [],
              effective_dates: [],
              type_return: type,
            },
          },
          api_parameters: {
            fast_positions,
            checking_factors,
            fast_sorting_conditions,
            rebalancing,
            reconstitution,
            currency: {
              currency,
            },
            returnCalculation: {
              type,
            },
            securities: {
              adrs,
            },
            fundamentals_to_filter,
          },
          front_parameters: summaryData,
          fundamentals: {},
          prices: {},
        };
        const methodology = {
          name,
          description,
          rules: data,
        };
        dispatch(SumaryActions.saveMethodology(methodology, token)).then(resp => {
          const methList = allMethodologies?.data?.slice() || []; // Initialize methList as an empty array if it's undefined

          if (Array.isArray(resp?.data?.data)) {
            methList.push(...resp.data.data); // Use spread (...) to push all elements from resp.data.data
          }

          const methodologyData = {
            ...resp?.data?.data,
            author_id: userName,
          };

          dispatch(
            setApiData({
              title: 'Methodologies',
              data: methList,
            }),
          );
          if (summaryData?.['Type Methodology']) {
            dispatch(
              setApiData({
                title: 'methodologySelected',
                data: methodologyData,
              }),
            );
            dispatch(
              setApiData({
                title: 'methodologySelectedCard',
                data: methodologyData,
              }),
            );
          }

          if (routeChange !== undefined) {
            routeChange();
          }
          if (clear) {
            clear();
          }
        });
      }
    } else {
      toast.error('Empty Summary ');
    }
  } catch (e) {
    toast.error('error saving methodology. Please try again');
    console.error('ERROR METHODOLOGY [x]: ', e);
    console.info('ERROR METHODOLOGY [x]: PARAMETERS ARE: summaryData ');
    console.info(summaryData);
  }
};

export default saveMethodology;
