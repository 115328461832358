import classnames from 'classnames';
import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';
import _ from 'lodash';
import { useCurrentModule, useSummary } from '../../bitaHooks';
import { useWindowSize } from '../../bitaHooks/useWindowSize';
import BitaModal from '../../bitaComponents/BitaModal/BitaModal';
import { Icons } from '../../constants/icons';
import * as SummaryActions from '../../state/actions/summary-actions';
import * as UniverseActions from '../../state/actions/universe-actions';
import PreviewValidate from './lib/PreviewValidate';
import PreviewValidateModal from './lib/PreviewValidateModal';
import IndexBuilderModal from './lib/IndexBuilderModal/IndexBuilderModal';
import SummaryEntry from './lib/SummaryEntry';
import SummaryItem from './lib/SummaryItem';
import BitaSpinner from '../BitaSpinner/BitaSpinner';
import styles from './Summary.module.scss';
import { getDataComponents } from '../../api-data-mapping/dataMap';
import { setBy } from '../../builder/helpers/deepSet';
import './summary.scss';
import { IconsModules } from '../../constants/icons-module';
import RunBacktestModal from '../../pages/runBacktestModal';
import { thousandFormat } from '../../utils/utils';

const mapDispatchToProps = dispatch => ({
  removeEntryFromSummary: (module, widget, entry) =>
    dispatch(SummaryActions.removeEntryFromSummary(module, widget, entry)),
  selectUniverse: universe => dispatch(UniverseActions.selectUniverse(universe)),
});

const summaryRoutes = [
  '/index-builders',
  '/universe-builder',
  '/structures-product',
  '/leverage-index',
  '/portfolio-basket-calculation',
];

const Summary = props => {
  // REDUX HOOKS
  const modalRef = useRef([]);
  const [loadingBar, setLoadingBar] = React.useState(35);
  const [currentModule] = useCurrentModule();
  const stateSummaryAll = useSelector(state => state.summary);
  const summary = useSelector(state => state.summary[currentModule]);
  const universeSelected = useSelector(state => state.moduleSelection.universeSelected);
  const universeState = useSelector(state => state.summary.universeBuilderOperations);
  const [addConstituents] = useSummary('universeBuilderOperations', 'constituents');
  const [addUniverse] = useSummary('universeBuilderOperations', 'filterUniverse');
  const [addFilters] = useSummary('universeBuilderOperations', 'Filters');
  const token = useSelector(state => state.auth.token);
  const history = useHistory();
  const { removeEntryFromSummary, location } = props;
  const [hide, setHide] = useState(false);
  const [constituents, setConstituents] = useState('');
  const [filteringDate, setFilteringDate] = useState('');
  const [currentRoute, setCurrentRoute] = useState('');
  const [selectedFilterGroupIndex, setSelectedFilterGroupIndex] = useState();
  const apiData = useSelector(state => state.apiData);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect());

  const dispatch = useDispatch();

  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect());
    setLastScrollTop(-bodyOffset.top);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  useEffect(() => {
    let total = 0;
    let actual = 0;
    if (universeState && universeState.universe) {
      total = parseInt(universeState.universe.constituents, 10);
      if (universeState.constituents || universeState.constituents === 0) {
        actual = universeState.constituents;
        setConstituents(universeState.constituents || 0);
      } else {
        actual = total;
        setConstituents(total || 0);
      }
    }
    setLoadingBar((actual * 100) / total);
  }, [universeState]);

  const AddIcon = iconM => {
    let Imagen;
    if (iconM === 'Universe Builder') {
      Imagen = IconsModules.UniverseModule;
      return Imagen;
    }
    if (iconM === 'Index Builder') {
      Imagen = IconsModules.IndexModule;
      return Imagen;
    }
    if (iconM === 'Structured Index') {
      Imagen = IconsModules.StructureIModule;
      return Imagen;
    }
    if (iconM === 'Optimized Index') {
      Imagen = IconsModules.StructureIModule;
      return Imagen;
    }
    return Imagen;
  };

  const size = useWindowSize();

  React.useEffect(() => {
    if (size.width < 769) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [window.innerWidth, document.documentElement.clientWidth, document.body.clientWidth]);

  // Classes for the Summary container.
  const summaryClasses = useMemo(() => {
    return classnames(
      `${styles.summaryPanel} `,
      // Depending on state toggle the width of the
      hide ? styles.summaryHidden : styles.summaryShown,
      // if current location doesn't match any of the specified routes then unmount the Summary from the DOM
      { [styles.summaryUnmount]: !summaryRoutes.some(path => location.pathname.startsWith(path)) },
    );
  }, [location, hide]);

  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const toggleBurger = useCallback(() => setHide(!hide), [setHide, hide]);

  // const deleteEntry = useCallback(keyId => removeEntryFromSummary(keyId), [removeEntryFromSummary]);

  // const hasChilds = useCallback(
  //   keysuffix => {
  //     // agarro las keys del summary que sean de esa seccion y subseccion
  //     const keys = Object.keys(props.summary).filter(key => key.startsWith(keysuffix));
  //     return keys.some(element => !isEmpty(props.summary[element]));
  //   },
  //   [props.summary],
  // );

  // const [modalRef,setModalRef] = useState(null)

  const validateRebalancingReconstitution = (format, type) => {
    let missing = '';
    if (format[type] === 'Same as Rebalancing') {
      return false;
    }
    if (!format.Day || format.Day === '') {
      missing = `${missing}${missing !== '' ? ', ' : ''}Day`;
    }
    if (!format.Months || format.Months === '') {
      if (format[type] !== 'Monthly') {
        missing = `${missing}${missing !== '' ? ', ' : ''}Months`;
      }
    }
    if (!format['Ex-Date'] || format['Ex-Date'] === '') {
      missing = `${missing}${missing !== '' ? ', ' : ''}Ex-Date`;
    }
    if (!format[type] || format[type] === '') {
      missing = `${missing}${missing !== '' ? ', ' : ''}Months`;
    }
    if (!format['Determination date'] || format['Determination date'] === '') {
      missing = `${missing}${missing !== '' ? ', ' : ''}Determination`;
    }
    const pluralOrSingular =
      missing.search(',') > -1 ? 'attributes are empty.' : 'attribute is empty.';
    return missing === '' ? false : `${missing} ${pluralOrSingular}`;
  };

  const runModal = () => {
    if (summary) {
      const moduleRoute = location.pathname.split('/')[1];
      if (moduleRoute === 'universe-builder') {
        if (constituents === 0) {
          toast('Your universe has 0 constituents.', {
            toastId: 'summary-toast',
          });
        } else {
          setCurrentRoute('universeBuilder');
          setPreviewModalOpen(true);
        }
      } else if (moduleRoute === 'index-builders') {
        if (
          _.has(summary, ['Rebalancing & Reconstitution']) ||
          summary?.['Type Methodology'] === 'Rebalancing and Reconstitution'
        ) {
          if (!summary['General Rules'].Currency) {
            toast('You must apply a Currency', {
              toastId: 'summary-toast',
            });
            return;
          }
          if (!summary['General Rules']['Weighting mechanism']) {
            toast('You must apply a Weighting mechanism', {
              toastId: 'summary-toast',
            });
            return;
          }

          if (!summary['General Rules']['Return calculation']) {
            toast('You must apply Return Calculation', {
              toastId: 'summary-toast',
            });
            return;
          }
          if (!summary?.['Type Methodology'] === 'Rebalancing and Reconstitution') {
            if (_.has(summary, ['Rebalancing & Reconstitution', 'Caps', 'Caps'])) {
              const caps = _.get(summary, ['Rebalancing & Reconstitution', 'Caps', 'Caps'], []);
              const ucit_list = caps.filter(cap => cap.Selected.value.indexOf('UCITS group') > -1);
              if (ucit_list.length > 1) {
                toast('You can only apply one UCITS group', {
                  toastId: 'summary-toast',
                });
                return;
              }
            }
            if (!_.has(summary, ['Rebalancing & Reconstitution', 'Rebalancing'])) {
              toast('You must apply a Rebalancing date', {
                toastId: 'summary-toast',
              });
              return;
            }

            if (!_.has(summary, ['Rebalancing & Reconstitution', 'Reconstitution'])) {
              toast('You must apply a Reconstitution date', {
                toastId: 'summary-toast',
              });
              return;
            }

            const rebalancingFormat = validateRebalancingReconstitution(
              _.get(summary, ['Rebalancing & Reconstitution', 'Rebalancing']),
              'Rebalancing',
            );
            const reconstitutionFormat = validateRebalancingReconstitution(
              _.get(summary, ['Rebalancing & Reconstitution', 'Reconstitution']),
              'Reconstitution',
            );

            if (rebalancingFormat) {
              toast(`Please select a proper Rebalancing Date. ${rebalancingFormat}`, {
                toastId: 'summary-toast',
              });
              return;
            }
            if (reconstitutionFormat) {
              toast(`Please select a proper Reconstitution Date. ${reconstitutionFormat}`, {
                toastId: 'summary-toast',
              });
              return;
            }
          }
          setCurrentRoute('indexBuilder');
          setPreviewModalOpen(true);
        } else {
          toast('You must apply a Rebalancing & Reconstitution date', {
            toastId: 'summary-toast',
          });
        }
      } else if (
        location.pathname &&
        (location.pathname === '/structures-product/structures-product/step=Decrement+Index' ||
          location.pathname === '/structures-product/structures-product/Leverage+Index' ||
          location.pathname === '/structures-product/structures-product/step=Risk+Control+Index')
      ) {
        if (
          Object.values(summary?.['Leverage Index'] || []).length === 4 ||
          Object.values(summary?.['Decrement Index'] || []).length === 4 ||
          Object.values(summary?.['Risk Control Index'] || []).length === 9
        ) {
          modalRef.current.runModal();
        } else {
          toast('You must apply all the rules', {
            toastId: 'summary-toast',
          });
        }
      } else if (
        location.pathname &&
        location.pathname === '/structures-product/structures-product'
      ) {
        if (!_.has(summary, ['New Structured Product', 'Weighting Mechanism'])) {
          toast('Please, select a Weighting Mechanism', {
            toastId: 'summary-toast',
          });
          return;
        }
        modalRef.current.runModal();
      } else if (
        location.pathname &&
        location.pathname === '/portfolio-basket-calculation/portfolio-basket-calculation'
      ) {
        if (!_.has(summary, ['Portfolio', 'Currency'])) {
          toast('Please, select a Currency', {
            toastId: 'summary-toast',
          });
          return;
        }
        if (!_.has(summary, ['Portfolio', 'Return calculation'])) {
          toast('Please, select a Return calculation', {
            toastId: 'summary-toast',
          });
          return;
        }
        if (!_.has(summary, ['Portfolio', 'Rebalancing'])) {
          toast('Please, select a Rebalancing', {
            toastId: 'summary-toast',
          });
          return;
        }
        modalRef.current.runModal();
      }
    } else {
      toast('You must apply at least one (1) filter.', {
        toastId: 'summary-toast',
      });
    }
  };
  const setFilter = () => {
    addConstituents('N/A');
    // const summaryTransformed = transform(summaryState);
    const summaryTransformed = summary;
    const filterGroupsdata = Object.keys(summary).map(filters => {
      const filtersData = {};
      Object.keys(summaryTransformed[filters]).forEach(filterKey => {
        if (
          summaryTransformed[filters][filterKey][filterKey] &&
          Array.isArray(summaryTransformed[filters][filterKey][filterKey])
        ) {
          filtersData[filterKey] = summaryTransformed[filters][filterKey][filterKey].map(row => {
            const fieldData = {};
            Object.keys(row).forEach(field => {
              if (row[field].value && (row[field].id || row[field].id === 0)) {
                fieldData[field] =
                  typeof row[field].id === 'number' ? row[field].id : row[field].value;
              } else {
                fieldData[field] = row[field];
              }
            });
            return fieldData;
          });
        } else {
          const fieldData = {};
          Object.keys(summaryTransformed?.[filters]?.[filterKey] ?? {}).forEach(field => {
            if (
              summaryTransformed?.[filters]?.[filterKey]?.[field]?.value &&
              (summaryTransformed?.[filters]?.[filterKey]?.[field]?.id ||
                summaryTransformed?.[filters]?.[filterKey]?.[field]?.id === 0)
            ) {
              fieldData[field] =
                typeof summaryTransformed[filters][filterKey][field].id === 'number'
                  ? summaryTransformed[filters][filterKey][field].id
                  : summaryTransformed[filters][filterKey][field].value;
            } else if (Array.isArray(summaryTransformed[filters][filterKey][field])) {
              fieldData[field] = summaryTransformed[filters][filterKey][field].map(val =>
                val.id || val.id === 0 ? val.id : val,
              );
            } else {
              fieldData[field] = summaryTransformed[filters][filterKey][field];
            }
          });
          filtersData[filterKey] = fieldData;
        }
      });
      // TODO ESG provider hardcoded
      if (filtersData['ESG Issues']) {
        filtersData['ESG Issues'] = filtersData['ESG Issues'];
        const { provider } = filtersData['ESG Issues'];
        filtersData['ESG Issues'].provider = provider === 'Sustainalytics' ? 7 : 6;
      }
      if (filtersData['ESG Ratings']) {
        filtersData['ESG Ratings'].forEach((i, index) => {
          const ratingProvider = filtersData['ESG Ratings'][index].provider;
          switch (ratingProvider) {
            case 'Sustainalytics':
              filtersData['ESG Ratings'][index].provider = '7';
              break;
            case 'Bita':
              filtersData['ESG Ratings'][index].provider = '8';
              break;
            default:
              filtersData['ESG Ratings'][index].provider = '6';
              break;
          }
        });
      }
      if (filtersData.Liquidity) {
        filtersData.Liquidity.forEach((item, index) => {
          if (item.indicator === 152 && (item.operator === '>=' || item.operator === '<=')) {
            item.value = (item.value / 100).toString();
          }
        });
      }
      if (filtersData.Geography) {
        filtersData.Geography.forEach((item, index) => {
          item.selection = item.selection.map(itemSel => {
            return itemSel.id;
          });
          if (item.value) {
            item.value /= 100;
          }
        });
      }
      if (filtersData['Single Instrument']) {
        if (
          filtersData['Single Instrument'].operator_type === 'Add' &&
          universeState?.universe?.['Universe Name'] === 'WHITE CANVAS' &&
          universeState?.constituents > 0
        ) {
          filtersData['Single Instrument'].operator_type = 'Add-in';
        }
      }
      const filterGroup = {
        delete: parseInt(filters.split(' ')[2], 10) === selectedFilterGroupIndex + 1,
        filters: {
          ...filtersData,
        },
      };
      return filterGroup;
    });
    let name = '';
    let id = 0;
    if (universeState) {
      name = universeState.universe ? universeState.universe.name : '';
      id = universeState.universe && universeState.universe.id;
    }
    const _filterType = Object.values(filterGroupsdata[0].filters).filter(
      e => e.operator_type || (e[0] ? e[0].operator_type : null),
    );
    const filterType =
      _filterType.length === 0
        ? false
        : typeof _filterType[_filterType.length - 1] === 'object'
        ? _filterType[_filterType.length - 1]
        : _filterType[_filterType.length - 1][0];
    if (filterType && !filterType?.operator_type && filterType[0]?.operator_type) {
      filterType.operator_type = filterType[0].operator_type;
    }
    if (filterGroupsdata[0].filters['iClima Themes']) {
      filterGroupsdata[0].filters = {
        ...filterGroupsdata[0].filters,
        Themes: filterGroupsdata[0].filters['iClima Themes'],
      };

      delete filterGroupsdata[0].filters['iClima Themes'];
    }
    const dataFilter = {
      base_universe: name,
      base_universe_id: id,
      type: typeof name !== 'undefined' ? 'exclusion' : 'inclusion',
      filter_groups: filterGroupsdata,
    };
    const dataComponent = getDataComponents.filter(
      v =>
        v.key === 'UniverseFilter' && v.type === 'UniverseFilter' && v.title === 'UniverseFilter',
    )[0];
    if (typeof dataComponent !== 'undefined') {
      const promise = dataComponent.getUrlData(dataFilter, token);
      promise
        .then(res => {
          const filterPath = `[${selectedFilterGroupIndex}].elements`;
          addUniverse(dataFilter);
          const elements = parseInt(res.elements, 10);
          addFilters(setBy(universeState ? universeState.Filters || {} : {}, filterPath, elements));
          addConstituents(res.elements);
          setFilteringDate(res.filtering_date);
          setSelectedFilterGroupIndex(null);
        })
        .catch(err => {
          toast.error('Something went wrong, filter not applied.');
          addConstituents(universeState.constituents);
          // removeFromSummary(key);
          setSelectedFilterGroupIndex(null);
        });
    }
  };
  useEffect(() => {
    if (typeof selectedFilterGroupIndex === 'number') {
      setFilter();
    }
  }, [selectedFilterGroupIndex]);

  const deleteEntry = (module, key, content) => {
    const summaryKeys = Object.keys(summary[key]);
    if (
      !_.has(stateSummaryAll, ['universeBuilderOperations', 'universe', 'id']) &&
      summaryKeys.length > 1 &&
      summaryKeys.indexOf(content) === 0
    ) {
      toast('Please, remove all the other filters before removing this one.', {
        toastId: 'delete_summary',
      });
      return;
    }
    if (currentModule === 'Universe Builder') {
      setSelectedFilterGroupIndex(parseInt(key.replace(/[^\d]+/g, ''), 10) - 1);
    }
    removeEntryFromSummary(module, key, content);
  };

  const getParentUniverse = idUniverse => {
    if (idUniverse) {
      const allUniverses = _.get(apiData, 'apiData.[AllUniverses]', {});
      const selectedUni = allUniverses.data.filter(u => u.id === idUniverse)[0];
      if (selectedUni) {
        return selectedUni.name || 'Not Found';
      }
    }
    return 'Not Found';
  };

  return (
    <div className={summaryClasses}>
      <div className={styles.header}>
        <button className={styles.bugerdiv} onClick={toggleBurger}>
          {hide ? (
            <div>
              <Icons.showMore className={styles.iconNormal} />
              <Icons.showMoreHover className={styles.iconHover} />
            </div>
          ) : (
            <div>
              <Icons.showLess className={styles.iconNormal} />
              <Icons.showLessHover className={styles.iconHover} />
            </div>
          )}
        </button>
        <div className={styles.title}>SUMMARY</div>
      </div>
      <div className={styles.contents}>
        <div className={styles.sectionTitle}>
          {currentModule && (
            <>
              {!!AddIcon(currentModule) && React.createElement(AddIcon(currentModule), {})}
              {currentModule === 'Index Builder' &&
              summary?.['Type Methodology'] === 'Rebalancing and Reconstitution'
                ? 'RR METHODOLOGY'
                : currentModule}
            </>
          )}
        </div>
        {universeState && universeState.universe && currentModule === 'Universe Builder' && (
          <div
            className={`${styles.sectionContent} ${
              stateSummaryAll['Universe Builder edit'] ? 'summaryUniverse' : ''
            }`}
          >
            {currentModule === 'Universe Builder' && (
              <div className={`${styles.summaryItem} `}>
                <div className={styles.summaryItemData} style={{ marginLeft: '0px' }}>
                  <div className={styles.subSection}>{universeState.universe.name}</div>
                </div>
              </div>
            )}
            <div className={styles.subSectionContent} style={{ display: 'flex' }}>
              Number of Constituents:{' '}
              {universeState.constituents === 'N/A' ? (
                <BitaSpinner pointers loading />
              ) : (
                thousandFormat(constituents)
              )}
            </div>
            <div>
              {filteringDate !== ''
                ? ` As of ${filteringDate} `
                : universeState.fintering_date
                ? ` As of ${universeState.fintering_date}`
                : ''}
            </div>
            <div className={styles.loadingBarBackground}>
              <div className={styles.loadingBar} style={{ width: `${loadingBar}%` }} />
            </div>
            <div className={styles.separator} />
          </div>
        )}
        {currentModule === 'Index Builder' && summary && summary['Methodology Name'] && (
          <div className={styles.sectionContent}>
            <div className={`${styles.summaryItem} `}>
              <button className={styles.summaryItemRemove}>
                <Icons.deleteIcon />
              </button>
              <div className={styles.summaryItemData}>
                <div className={styles.subSection}>{summary['Methodology Name']}</div>
              </div>
            </div>
            <div className={styles.separator} />
          </div>
        )}
        {currentModule === 'Portfolio' && summary && summary.PortfolioSelected && (
          <div className={styles.sectionContent}>
            <div className={`${styles.summaryItem} `}>
              <div className={styles.summaryItemData}>
                <div className={styles.subSection}>{summary.PortfolioSelected.value}</div>
              </div>
            </div>
            <div className={styles.separator} />
          </div>
        )}
        {currentModule === 'Structured Index' && summary && summary['Instrument Selected'] && (
          <div className={styles.sectionContent}>
            <div className={`${styles.summaryItem} `}>
              <button
                className={styles.summaryItemRemove}
                onClick={() => {
                  history.push(
                    '/structures-product/structures-product/step=STRUCTURED+INDEX+BUILDER',
                  );
                }}
              >
                <Icons.deleteIcon />
              </button>
              <div className={styles.summaryItemData}>
                <div className={styles.subSection}>
                  {summary['Instrument Selected'].map(item => ` ${item?.['Instrument name']}, `)}
                </div>
              </div>
            </div>
            <div className={styles.separator} />
          </div>
        )}
        <div className={`${lastScrollTop < 70 && styles.sectionsContainer} summaryEdit`}>
          {stateSummaryAll?.universeBuilderOperations?.universe?.parent_universe_id && (
            <div className={styles.section}>
              <div className={styles.sectionContent}>
                <div className={styles.subSectionTitle}>Base Universe</div>
                <SummaryItem
                  key="universe_name"
                  title={getParentUniverse(
                    stateSummaryAll.universeBuilderOperations.universe.parent_universe_id,
                  )}
                />
              </div>
            </div>
          )}

          {stateSummaryAll['Universe Builder edit'] &&
            Object.entries(stateSummaryAll['Universe Builder edit']).map(
              ([summaryKey, summaryValue]) => {
                if (summaryKey === 'Instrument Selected') {
                  return <div> </div>;
                }
                return (
                  <div key={summaryKey} className={styles.section}>
                    <div className={styles.sectionContent}>
                      {Array.isArray(summaryValue)
                        ? summaryValue.map((content, index) => (
                            <div>
                              <div className={styles.subSectionTitle}>{`${summaryKey} #${index +
                                1}`}</div>
                              <div>
                                {content &&
                                  Object.entries(content).map(entry => {
                                    const [contentKey, contentValue] = entry;
                                    return (
                                      <SummaryItem key={contentKey} title={contentKey}>
                                        <SummaryEntry type="EXAMPLE" data={contentValue} />
                                      </SummaryItem>
                                    );
                                  })}
                              </div>
                            </div>
                          ))
                        : typeof summaryValue === 'object' && (
                            <div>
                              <div className={styles.subSectionTitle}>
                                {currentModule !== 'Structured Index' && summaryKey}
                              </div>
                              <div className={styles.subSectionContent}>
                                {Object.entries(summaryValue).map(([contentKey, contentValue]) => (
                                  <SummaryItem key={contentKey} title={contentKey}>
                                    {contentKey === 'Rebalancing' ||
                                    contentKey === 'Reconstitution' ? (
                                      <SummaryEntry
                                        type="Rebalancing-Reconstitution"
                                        data={contentValue}
                                      />
                                    ) : contentKey === 'Securities Considerations' ? (
                                      <SummaryEntry
                                        type="securities-considerations"
                                        data={contentValue}
                                      />
                                    ) : (
                                      <SummaryEntry type="EXAMPLE" data={contentValue} />
                                    )}
                                  </SummaryItem>
                                ))}
                              </div>
                            </div>
                          )}
                    </div>
                  </div>
                );
              },
            )}
        </div>
        <div data-simplebar style={{ minHeight: '390px', height: '100%', overflow: 'auto' }}>
          <div>
            {summary &&
              Object.entries(summary).map(([summaryKey, summaryValue]) => {
                if (summaryKey === 'Instrument Selected' || summaryKey === 'PortfolioSelected') {
                  return <div key={summaryKey}> </div>;
                }
                return (
                  <div key={summaryKey} className={styles.section}>
                    <div className={styles.sectionContent}>
                      {Array.isArray(summaryValue)
                        ? summaryValue.map((content, index) => (
                            <div>
                              <div className={styles.subSectionTitle}>{`${summaryKey} #${index +
                                1}`}</div>
                              <div>
                                {content &&
                                  Object.entries(content).map(entry => {
                                    const [contentKey, contentValue] = entry;
                                    return (
                                      <SummaryItem
                                        key={contentKey}
                                        title={contentKey}
                                        handleDelete={() => {
                                          deleteEntry('Universe Builder', 'Filter Group', index);
                                        }}
                                      >
                                        <SummaryEntry type="EXAMPLE" data={contentValue} />
                                      </SummaryItem>
                                    );
                                  })}
                              </div>
                            </div>
                          ))
                        : typeof summaryValue === 'object' && (
                            <div>
                              <div className={styles.subSectionTitle}>
                                {currentModule !== 'Structured Index' &&
                                  Object.values(summaryValue).length > 0 &&
                                  summaryKey}
                              </div>
                              <div className={styles.subSectionContent}>
                                {Object.entries(summaryValue).map(([contentKey, contentValue]) => (
                                  <SummaryItem
                                    key={contentKey}
                                    title={contentKey}
                                    handleDelete={() => {
                                      deleteEntry(currentModule, summaryKey, contentKey);
                                    }}
                                  >
                                    {contentKey}
                                    {contentKey === 'Rebalancing' ||
                                    contentKey === 'Reconstitution' ? (
                                      <SummaryEntry
                                        type="Rebalancing-Reconstitution"
                                        data={contentValue}
                                      />
                                    ) : contentKey === 'Securities Considerations' ? (
                                      <SummaryEntry
                                        type="securities-considerations"
                                        data={contentValue}
                                      />
                                    ) : (
                                      <SummaryEntry type="EXAMPLE" data={contentValue} />
                                    )}
                                  </SummaryItem>
                                ))}
                              </div>
                            </div>
                          )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {(location.pathname === '/structures-product/structures-product' ||
        location.pathname === '/structures-product/structures-product/step=Decrement+Index' ||
        location.pathname === '/structures-product/structures-product/Leverage+Index' ||
        location.pathname === '/structures-product/structures-product/step=Risk+Control+Index') && (
        <RunBacktestModal ref={modalRef} ubication="structured" disable_title={false} showData />
      )}

      {location.pathname === '/portfolio-basket-calculation/portfolio-basket-calculation' && (
        <RunBacktestModal ref={modalRef} ubication="portfolio" disable_title={false} showData />
      )}

      {!hide && <PreviewValidate handleClick={runModal} />}
      {/* TODO se remplazó por el modal nuevo, supongo que habrá que hacer una limpieza de esto */}
      {/* Modal con su correspondiente estado de true o false para ver si esta abierto  */}
      <BitaModal isModalOpen={previewModalOpen} setModalState={setPreviewModalOpen}>
        {currentRoute === 'universeBuilder' ? (
          <PreviewValidateModal
            toggleModal={setPreviewModalOpen}
            selectUniverse={props.selectUniverse}
            universeSelected={universeSelected}
          />
        ) : (
          <IndexBuilderModal
            toggleModal={setPreviewModalOpen}
            // summaryData={{ ...summaryModuleData('indexOperations') }}
            summaryData={summary}
          />
        )}
      </BitaModal>
    </div>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(Summary);
export default connect(null, mapDispatchToProps)(Summary);
