/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import SimpleBar from 'simplebar';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { useLocation } from 'react-router';
import axios from '../../../Axios';
import BitaButton from '../../../bitaComponents/BitaButton/BitaButton';
import Switcher from '../../../bitaComponents/Switcher/Switcher';
import { useSummary } from '../../../bitaHooks';
import BitaCard from '../../../components/BitaCard/BitaCard';
import BitaCheckbox from '../../../components/BitaCheckbox/BitaCheckbox';
import BitaCalendarDate from '../../../components/BitaCalendar/BitaCalendarDate';
import BitaInput from '../../../components/BitaInput/BitaInput';
import BitaSelect from '../../../components/BitaSelect/BitaSelect';
import { Icons } from '../../../constants/icons';
import { IconsModules } from '../../../constants/icons-module';
import styles from './pagebuilder.module.scss';
import UserManager from '../../../components/ComponetsSettings/UserManager/UserManager';
import { getDataComponents } from '../../../api-data-mapping/dataMap';
import ContentManager from '../../../components/ComponetsSettings/ContentManager/ContentManager';
import DataUpload from '../../../components/ComponetsSettings/DataUpload/DataUpload';
import SelectionMatrix from '../../../components/ComponetsSettings/SelectionMatrix/SelectionMatrix';
import BitaModal from '../../../bitaComponents/BitaModal/BitaModal';
import * as ApiDataActions from '../../../state/actions/api-data-actions';
import * as AuthActions from '../../../state/actions/auth-actions';
import { setFile } from '../../../state/actions/module-upload-actions';
import { BitaBrandingModal } from '../../../components/BitaBrandingModal/BitaBrandingModal';
import './pageBuilder.css';

const iconF = iconY => {
  switch (iconY) {
    case 'parametrization':
      return Icons.UniverseIconparametrization;
    // La siguiente linea esta comentada, porque de lo contrario afecta los iconos de content Manager
    // case 'usermanagement':
    //   return IconsModules.SettingsUsermanagement;
    case 'contentmanager':
      return IconsModules.SettingsContentManager;
    case 'databaseselection':
      return IconsModules.SettingsDatabaseSelection;
    case 'dataupload':
      return IconsModules.SettingsDataUpload;
    case 'customization':
      return Icons.CustomizationIcon;
    default:
      return Icons.account;
  }
};

const formatApiData = (apiData, key, exclude) => {
  if (apiData && key && apiData[key] && apiData[key].data) {
    const res = (key === 'Benchmark' ? apiData[key].data.Benchmark : apiData[key].data)
      .filter(field => {
        return field.author !== exclude;
      })
      .map(filtered => filtered);
    return res;
  }
  return [];
};

const PageBuilderComponent = props => {
  let Image = IconsModules.StructureIModule;
  if (props.header_icontext === 'StructureIModule') {
    Image = IconsModules.StructureIModule;
  }

  if (props.ignoreFirst) {
    const children = props.components || props.widgets;
    return Object.values(children).map(child => (
      <PageBuilderComponent {...props} {...child} ignoreFirst={false} />
    ));
  }
  if (props.cards) {
    return (
      <div className={styles.cardPageBuilderComponentContainer}>
        {Object.values(props.cards).map(card => (
          <PageBuilderComponent
            key={card.step_number}
            {...props}
            cards={null}
            {...card}
            type="card"
          />
        ))}
      </div>
    );
  }
  if (props.type === 'Default') {
    return (
      <div className={styles.pageContainer}>
        {props.title ? (
          <h2 className={styles.defaultPageTitle}>
            <Image /> {props.title.toUpperCase()}
          </h2>
        ) : null}
        <div className={props.defaultWidgetsContainerClass}>
          {Object.values(props.widgets).map((widget, index) => (
            <PageBuilderComponent
              {...props}
              title={null}
              name={null}
              widgets={null}
              {...widget}
              kind="widget"
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }

  if (props.type === 'card') {
    return (
      <BitaCard
        className={styles.optimizationCard}
        title={props.title}
        text={props.description}
        cardType={BitaCard.FILTER}
        icon={Icons.account}
        onClick={() => {
          if (props.onclick) {
            props.setStepData(prevData => ({ ...prevData, typeSelected: props.title }));
            props.nextStep();
          }
        }}
      />
    );
  }
  if (props.type === 'SearchBar') {
    return (
      <BitaInput
        value={props.stepData[props.index] && props.stepData[props.index].searchBar}
        handleChange={value =>
          props.setStepData(prev => ({ ...prev, [`${props.index}`]: { searchBar: value } }))
        }
        placeholder={props.max_instruments_selected ? 'Search Instrument' : 'Search...'}
        autoFocus
      />
    );
  }
  if (props.type === 'BrandingInput') {
    return <PageBuilderBrandingInput {...props} />;
  }
  if (props.type === 'TextField') {
    return <PageBuilderTextField {...props} />;
  }
  if (props.type === 'CustomBranding') {
    return <PageCustomBrandingComponent {...props} />;
  }
  if (props.type === 'Palette') {
    return <PagePaletteComponent {...props} />;
  }
  if (props.type === 'CustomText') {
    return <PageCustomTextComponent {...props} />;
  }
  if (props.type === 'Input') {
    return <PageBuilderInputComponent {...props} />;
  }
  if (props.type === 'Toggle') {
    // eslint-disable-next-line no-use-before-define
    let Component = PageBuilderSwitchComponent;

    if (props.kind === 'widget') {
      // eslint-disable-next-line no-use-before-define
      Component = PageBuilderWidgetSwitchComponent;
    }
    if (props.options) {
      return (
        <PageBuilderSwitchHolder
          {...props}
          setWidgetData={props.setWidgetData}
          Component={Component}
          options={props.options}
        />
      );
    }
    return <Component {...props} />;
  }
  if (props.type === 'VariablesList') {
    return <PageVariablesListComponent {...props} />;
  }
  if (props.type === 'PopUp') {
    return <PagePopUpComponent {...props} />;
  }
  if (props.type === 'Apply') {
    return <PageApplyComponent {...props} />;
  }
  if (props.type === 'Calendar') {
    return <BitaCalendarDate />;
  }
  if (props.type === 'RatioButtons') {
    return <PageBuilderRatioButtons {...props} />;
  }
  if (props.type === 'UserManager') {
    return <PageUserManagerComponent {...props} />;
  }
  if (props.type === 'Dropdown' && props.options) {
    return <PageBuilderDropdownComponent {...props} />;
  }
  if (props.type === 'AddibleRows') {
    return <PageBuilderAddibleRowsComponent {...props} />;
  }
  if (props.type === 'CheckBox') {
    return <PageBuilderCheckboxComponent {...props} />;
  }
  if (props.type === 'ContentManager') {
    return <PageContentManagerComponent {...props} />;
  }
  if (props.type === 'SelectionMatrix') {
    return <PageSelectionMatrixComponent {...props} />;
  }
  if (props.type === 'Subtitle') {
    return <h2 className={styles.subTitle}>{props.title}</h2>;
  }
  if (props.type === 'Button') {
    return <PageBuilderButtonComponent {...props} />;
  }
  if (props.type === 'Tabs') {
    return <PageBuilderTabsComponent {...props} />;
  }
  if (props.type === 'Text') {
    return <div className={styles.optimizationText}> {props.text} </div>;
  }
  if (props.type === 'Title') {
    return <PageBuilderTitleContentComponent {...props} />;
  }
  if (props.type === 'Item') {
    return (
      <div className="structureItem">
        {Object.entries(props.items).map(([_, item]) => (
          <PageBuilderComponent {...props} {...item} />
        ))}
      </div>
    );
  }
  if (props.type === 'DataUpload') {
    return <PageDataUploadComponent {...props} />;
  }
  if (props.type === 'UploadFile') {
    return <PageUploadInputComponent {...props} />;
  }
  return (
    <>
      <span>Unknown element &quot;{props.type}&quot;</span>
    </>
  );
};

const PageBuilderTitleContentComponent = props => {
  const ImageT = iconF(props.icon);
  return (
    <div className={styles.optimizationTitleComponentContainer}>
      <h2 className={styles.optimizationWidgetTitle}>
        <ImageT />
        <span /> {props.title}
      </h2>
    </div>
  );
};

const PageBuilderButtonComponent = ({
  primary = false,
  primaryWhite = true,
  title = 'Untitled Action',
  className = styles.button,
  closesmodal = false,
  onClosePopUp,
  action,
  disabled,
  ...props
}) => {
  const userData = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const downloadFile = props.download_file;
  const dispatch = useDispatch();
  const onClickLocal = useCallback(() => {
    if (action) {
      setLoading(true);
      const dataComponent = getDataComponents.filter(
        v => v.key === action && v.title === title && v.type === props.type,
      )[0];
      dataComponent
        .getUrlData(props.widgetData, userData.token)
        .then(res => {
          if (props.widgetData.data_api_key) {
            const data = {
              data_api_key: props.widgetData.data_api_key,
              type: props.widgetData.data_type,
            };
            dispatch(ApiDataActions.getUsers(data, userData.token));
          }
          toast('Read user succesfully created.');
          if (onClosePopUp) {
            setTimeout(onClosePopUp, 1000);
          }
        })
        .catch(res => {
          toast.error(
            'There has been an error when trying to create the user. Please, contact support for solving this problem.',
          );
          if (onClosePopUp) {
            setTimeout(onClosePopUp, 1000);
          }
        });
    }
    if (props.contentManagerDelete) {
      setLoading(true);
      dispatch(ApiDataActions.delteEntity(props.contentManagerDelete, userData.token));
      setTimeout(onClosePopUp, 1000);
      dispatch(ApiDataActions.setUniversesList(userData.token));
      dispatch(ApiDataActions.setMethodologiesList(userData.token));
      dispatch(ApiDataActions.setIndexesList(userData.token));
    }
    if (onClosePopUp) {
      setTimeout(onClosePopUp, 1000);
    }
  }, [onClosePopUp, action, props, userData.token, title, dispatch]);

  const downloadTemplate = () => {
    axios
      .get(`${process.env.PUBLIC_URL}/files/${downloadFile}`, { responseType: 'blob' })
      .then(response => {
        FileSaver.saveAs(response.data, downloadFile);
      });
  };

  return (
    <>
      <BitaButton
        primary={primary}
        primaryWhite={primaryWhite && !primary}
        className={className}
        disabled={disabled || false}
        loading={loading}
        onClick={downloadFile === undefined ? onClickLocal : downloadTemplate}
      >
        {loading ? 'Loading' : title}
      </BitaButton>
    </>
  );
};

const PageVariablesListComponent = props => {
  const { selectedInstruments = [] } = props;

  const [rows, setRows] = useState(
    selectedInstruments.map((instrument, index) => ({
      id: index,
      weight: 0,
      description: instrument['Instrument name'],
      ...instrument,
    })),
  );

  const total = useMemo(
    () => `${rows.reduce((acc, row) => acc + parseInt(row.weight || 0, 10), 0)} %`,
    [rows],
  );

  useEffect(() => {
    props.setWidgetData(prev => ({ ...prev, rows }));
    // eslint-disable-next-line
  }, [rows]);

  return (
    <div>
      {rows.map(instrument => (
        <div key={instrument.id} className={styles.variablesListRow}>
          <div>{instrument.description}</div>
          <div>
            <b>Weight</b>{' '}
            <BitaInput
              className={styles.widgetInput}
              value={instrument.weight}
              type="number"
              handleChange={value => {
                setRows(
                  rows.map(row => {
                    if (instrument.id === row.id) {
                      return { ...row, weight: value };
                    }
                    return row;
                  }),
                );
              }}
            />
          </div>
        </div>
      ))}
      <div className={styles.variablesListRow}>
        <div />
        <div>
          <b>Total Weight</b>
          <BitaInput className={styles.widgetInput} value={total} />
        </div>
      </div>
    </div>
  );
};

const PageBuilderSwitchComponent = props => {
  const [checked, setChecked] = useState(
    (props.groupSwitch && props.groupSwitch.switchOn) || false,
  );

  useEffect(() => {
    if (props.groupSwitch) setChecked(props.groupSwitch.switchOn);
  }, [props.groupSwitch]);

  const name = useMemo(() => props.title || props.name, [props.title, props.name]);
  const toggle = useCallback(() => {
    if (props.groupSwitch) {
      setChecked(!checked);
      props.groupSwitch.setSwitchOnIndex(props.groupSwitch.index);
    } else {
      if (props.labelon && props.labeloff)
        props.setWidgetData(prev => ({
          ...prev,
          label: !checked ? props.labelon : props.labeloff,
        }));
      setChecked(!checked);
    }
  }, [checked, setChecked, props]);

  return (
    <div className={styles.otimizationSwitchComponentContainer}>
      <div
        className={classnames(
          styles.optimizationSwitchComponentTop,
          props.components_direction || 'vertical',
        )}
      >
        {name && <b>{name}</b>}
        {props.labeloff && <b>{props.labeloff}</b>}
        <span style={{ display: 'inline-block', marginRight: '30px' }}>
          <Switcher.Switch onChange={toggle} checked={checked} />
        </span>
        {props.labelon && <b>{props.labelon}</b>}
      </div>
      {props.onclick &&
        checked &&
        Object.values(props.onclick).map((comp, index) => (
          <>
            {index % 3 === 0 && <div />}
            <div className={`${styles.optimizationSwitchContent} optimizationSwitchContent `}>
              <PageBuilderComponent
                {...props}
                onclick={null}
                components={null}
                type="Toggle"
                kind={null}
                {...comp}
              />
            </div>
          </>
        ))}
    </div>
  );
};

const PageCustomTextComponent = props => {
  return (
    <div>
      {Object.values(props.components || []).map(component => (
        <PageBuilderComponent
          {...component}
          widgetData={props.widgetData}
          handleChange={props.handleChange}
        />
      ))}
    </div>
  );
};

const PaletteItem = ({ id, value, onChange, title, description }) => {
  const [show, setShow] = useState(false);
  const [colorValue, setColor] = useState(value);
  const openPalette = () => {
    setShow(!show);
  };
  const handleChange = newValue => {
    onChange(id, newValue);
    setColor(newValue);
  };
  return (
    <div className={styles.paleteOptionContainer} key={id}>
      <BitaBrandingModal
        title={`${title} Color`}
        init_value={colorValue}
        open={show}
        closeModal={openPalette}
        saveChange={handleChange}
        description={description}
        type="color"
      />
      <div
        className={styles.paleteColor}
        style={{ backgroundColor: `${colorValue || '#fff'}` }}
        onClick={() => openPalette()}
      >
        {colorValue?.toLowerCase() === '#ffffff' ? (
          <Icons.ColorEditBlackIcon className={styles.colorEditIcon} />
        ) : (
          <Icons.ColorEditIcon className={styles.colorEditIcon} />
        )}

        <div className={styles.dot} />
      </div>
      <span>{title}</span>
    </div>
  );
};

const PagePaletteComponent = props => {
  return (
    <div className={styles.brandingPaleteContainer}>
      {Object.values(props.options).map(option => (
        <PaletteItem
          title={option.title}
          id={option.id}
          value={props.widgetData?.[option.id] || '#fff'}
          onChange={props.handleChange}
          description={option.description}
          key={`${option.title}-${option.id}`}
        />
      ))}
    </div>
  );
};

const PageCustomBrandingComponent = props => {
  const [newCustom, setCustomization] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const dispatch = useDispatch();
  const handleChange = (id, value) => {
    const newState = newCustom;
    newState[id] = value;
    setCustomization(newState);
    setDisabledSave(false);
  };
  const customBrandingRef = React.useRef();
  const location = useLocation();
  const userdata = useSelector(state => state.auth.userdata);
  const token = useSelector(state => state.auth.token);
  useEffect(() => {
    if (userdata) {
      setCustomization({
        ...userdata.customization.colors,
        ...userdata.customization.texts,
        ...{ company: userdata?.company.name },
        ...{ website: userdata?.customization?.website || false },
        ...{ logo: userdata?.customization?.logo || false },
      });
    }
  }, [userdata]);

  useEffect(() => {
    if (location?.state?.goToBottom) {
      customBrandingRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }, [customBrandingRef.current]);

  const resetCustomization = () => {
    const body = { ...userdata.customization };
    body.colors = {
      primary_chart: '#2962FF',
      secondary_chart: '#5981ED',
      tertiary_chart: '#FA5400',
      quaternary_chart: '#BFBFBF',
      text: '#000000',
      header: '#FFFFFF',
      gradient_left: '#2962FF',
      gradient_right: '#11259e',
      separator: '#2962FF',
      list_background: '#C2D0F7',
      primary_header: '#2962FF',
      secondary_header: '#5981ED',
      tertiary_header: '#616870',
    };
    setCustomization(false);
    dispatch(AuthActions.updateCustomization(body));
  };

  const saveCustomization = () => {
    setLoading(true);
    const body = { ...userdata.customization };
    body.colors.primary_chart = newCustom.primary_chart;
    body.colors.separator = newCustom.primary_chart;
    body.colors.primary_header = newCustom.primary_chart;
    body.colors.secondary_chart = newCustom.secondary_chart;
    body.colors.secondary_header = newCustom.secondary_chart;
    body.colors.tertiary_chart = newCustom.tertiary_chart;
    body.colors.header = newCustom.header;
    body.colors.gradient_left = newCustom.gradient_left;
    body.colors.gradient_right = newCustom.gradient_right;
    body.texts.about = newCustom.about;
    body.texts.disclaimer = newCustom.disclaimer;
    body.website = newCustom.website;
    body.logo = newCustom.logo;
    body.name = newCustom.company;
    delete body._id;
    if (userdata.company.custom) {
      body.id = userdata.customization._id;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/custom-company/${userdata.id_company}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(res => {
        body._id = res?.data?.message?.customization;
        dispatch(AuthActions.updateCustomization(body));
        dispatch(AuthActions.companyChange(newCustom.company));
        setLoading(false);
        toast('Custom branding successfully applied.');
      })
      .catch(e => {
        setLoading(false);
        toast('Error updating Custom branding');
        console.info('Error updating customization', e);
      });
  };

  return (
    <div ref={customBrandingRef}>
      <div className={styles.brandingContainer}>
        {newCustom &&
          Object.values(props.components || []).map(component => (
            <PageBuilderComponent
              key={newCustom}
              widgetData={newCustom}
              {...component}
              handleChange={handleChange}
            />
          ))}
      </div>
      <div className={styles.brandingButton}>
        <BitaButton
          primary
          height="35px"
          width="170px"
          loading={loading}
          onClick={() => resetCustomization()}
          style={{ marginRight: 15 }}
        >
          Reset to Default Colors
        </BitaButton>

        <BitaButton
          primary
          height="35px"
          width="148px"
          disabled={disabledSave}
          loading={loading}
          onClick={() => saveCustomization()}
        >
          Save Changes
        </BitaButton>
      </div>
    </div>
  );
};

const PagePopUpComponent = props => {
  const [visible, setVisible] = useState(true);

  const customStyle = useMemo(() => {
    const style = {};
    if (props.size && props.size === 'small') {
      style.width = '550px';
    }
    return style;
  }, [props]);

  const popupText = useMemo(() => {
    if (props.text && props.text.length) {
      return props.text
        .split('\n')
        .map(paragraph => <p className={styles.popUpText}>{paragraph}</p>);
    }

    return null;
  }, [props.text]);

  return (
    <BitaModal customStyle={customStyle} isModalOpen={visible} setModalState={setVisible}>
      <div className={styles.pupUpTitleContainer}>
        <Icons.Avanti className={styles.popUpAvantiIcon} />
        <h2 className={styles.popUpTitle}>{props.title}</h2>
        <span onClick={() => setVisible(false)} className={styles.popUpCloser}>
          &times;
        </span>
      </div>
      {popupText}
      <div className={styles.popUpContent}>
        {Object.values(props.components || []).map(component => (
          <PageBuilderComponent
            setStepData={props.setStepData}
            setWidgetData={props.setWidgetData}
            stepData={props.stepData}
            widgetData={props.widgetData}
            contentManagerDelete={props.contentManagerDelete}
            {...component}
            onClosePopUp={() => setVisible(false)}
          />
        ))}
      </div>
    </BitaModal>
  );
};
const PageUploadInputComponent = ({
  primary = false,
  primaryWhite = true,
  title = 'Untitled Action',
  className = styles.button,
  ...props
}) => {
  const [files, setFiles] = useState('');
  const [disabled, setDisabled] = useState(true);
  const stateApiData = useSelector(state => state.upload.filesettings);
  const imageInputRef = React.useRef(); // See Supporting Documentation #2
  const dispatch = useDispatch();
  useEffect(() => {
    if (stateApiData) {
      setFiles(false);
      imageInputRef.current.value = ''; // Resets the file name of the file input - See #2
    }
  }, [stateApiData]);

  const onchange = value => {
    setFiles(value);
  };
  useEffect(() => {
    if (props?.widgetData?.Value?.id) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [props?.widgetData]);

  return (
    <>
      <input
        type="file"
        onChange={event => {
          dispatch(setFile(false));
          setFiles(event.target.files[0]);
          if (props.onChangeFile) props.onChangeFile(event.target.files[0]);
        }}
        name="file"
        id="file"
        ref={imageInputRef}
        className={styles.inputfile}
        accept={props?.widgetData?.Value?.value === 'Benchmark' ? '.xls,.xlsx' : ''} // TODO the widgetData structure must be changed so that value is lowercase and the property is not duplicated unnecessarily
      />
      <label
        htmlFor="file"
        style={{ opacity: !disabled ? '1' : '0.5', pointerEvents: !disabled ? '' : 'none' }}
      >
        {' '}
        {title}{' '}
      </label>
      <br />
      <div style={{ color: '#a7a7aa', height: '20px' }}>
        <span style={{ color: '#a7a7aa', height: '20px' }}>{files && files.name}</span>
      </div>
    </>
  );
};
const PageApplyComponent = props => {
  const [widgetData, setWidgetData] = useState({});

  const ImageT = iconF(props.icon);

  return (
    <div className={classnames('content-Widget', styles.applyWidget)}>
      <h2 className={styles.applyWidgetTitle}>
        {props.icon ? <ImageT /> : ' '}
        {props.title}
      </h2>

      <br />

      {Object.values(props.components || {}).map((component, index) => (
        <PageBuilderComponent
          {...component}
          applyLabel={props.applyLabel}
          setWidgetData={setWidgetData}
          widgetData={widgetData}
          setStepData={props.setStepData}
          stepData={props.stepData}
          key={index}
        />
      ))}
    </div>
  );
};
const PageDataUploadComponent = props => {
  const [filteUpload, setFileUpload] = useState(false);
  const [typeFile, setTypeFile] = useState(false);
  const apiData = useSelector(state => state.apiData.apiData);

  useEffect(() => {
    console.log(props.components, props.widgetData);
  }, [props.components, props.widgetData]);

  return (
    <div style={{ height: '100px' }}>
      <DataUpload
        buttonsModal={props.buttonmodal}
        contentModal={props.onclick}
        filteUpload={filteUpload}
        typeFile={typeFile}
        data={apiData.CustomDataFields}
      >
        {Object.values(props.components || {}).map((component, index) => (
          <PageBuilderComponent
            {...component}
            setWidgetData={props.setWidgetData}
            widgetData={props.widgetData}
            onChangeFile={uploadFile => setFileUpload(uploadFile)}
            onChangeDropdown={typeF => setTypeFile(typeF)}
            key={index}
          />
        ))}
      </DataUpload>
    </div>
  );
};
const PageContentManagerComponent = props => {
  const [components, setComponents] = useState([]);
  const apiData = useSelector(state => state.apiData.apiData);
  const userdata = useSelector(state => state.auth.userdata);
  const [dataDelete, setDataDelete] = useState(null);
  const [byAutor, setByAutor] = useState();
  const [data, setData] = React.useState(
    props.data_api_key && apiData
      ? formatApiData(apiData, props.data_api_key, props.data_exclude)
      : props.data
      ? Object.values(props.data)
      : [],
  );

  useEffect(() => {
    setData(
      props.data_api_key && apiData
        ? formatApiData(apiData, props.data_api_key, props.data_exclude)
        : props.data
        ? Object.values(props.data)
        : [],
    );
  }, [props.data_api_key, props.data_exclude, props.data, apiData]);
  const options = useMemo(
    () =>
      Object.values(props.options || {}).map(action => {
        const res = { ...action };
        if (res.onclick) {
          res.onClick = () => {
            setComponents(Object.values(res.onclick));
          };
        }
        return res;
      }),
    [props, setComponents],
  );

  const filterAutor = autor => {
    setByAutor(autor);
  };

  const style = {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
    marginTop: '-40px',
  };

  return (
    <div className={`contentManager-${props.dropdownSelected}`}>
      <br />
      {props.dropdownSelected !== 'Benchmark' ? (
        <PageBuilderRatioButtons
          options={[
            { name: 'Created by Organization' },
            { name: `Created by ${userdata.first_name} ${userdata.last_name}` },
          ]}
          onchange={filterAutor}
          style={style}
        />
      ) : (
        ''
      )}
      <ContentManager
        data={data || []}
        key={`contentManager-${props.dropdownSelected}`}
        type={props.dropdownSelected}
        options={options}
        actions={Object.values(props.actions || {})}
        searchBar={props.search_bar || false}
        onApply={dataDeleteAux => {
          setComponents([]);
          setDataDelete({ ...dataDeleteAux, type: props.dropdownSelected });
          setTimeout(() => setComponents(Object.values(props.onapply)));
        }}
        optionSelected={byAutor}
        noDataText={props.no_data_text}
      />
      {components.map(component => (
        <PageBuilderComponent {...component} contentManagerDelete={dataDelete || false} />
      ))}
    </div>
  );
};

const PageSelectionMatrixComponent = props => {
  const [components, setComponents] = useState([]);

  const onApply = useCallback(() => {
    if (props.onapply) {
      setComponents([]);
      setTimeout(() => setComponents(Object.values(props.onapply)));
    }
  }, [props.onapply, setComponents]);

  const onClick = useCallback(() => {
    if (props.onclick) {
      setComponents([]);
      setTimeout(() => setComponents(Object.values(props.onclick)));
    }
  }, [props.onapply, setComponents]);

  return (
    <>
      <br />
      <SelectionMatrix
        rows={Object.values(props.rows || {})}
        columns={Object.values(props.colums || {})}
        onApply={onApply}
        onClick={onClick}
      />
      {components.map(component => (
        <PageBuilderComponent {...component} />
      ))}
    </>
  );
};

const PageBuilderRatioButtons = props => {
  const [selectRadioButton, setSelectRadioButton] = useState(1);
  return (
    <div style={props.style}>
      {Object.values(props.options).map((option, index) => (
        <button
          onClick={() => {
            setSelectRadioButton(index);
            if (props.onchange) props.onchange(option.name);
          }}
          className={styles.radioButton}
        >
          <div>
            <input
              id={`radio-${index}`}
              type="radio"
              name={props.name}
              checked={selectRadioButton === index}
            />
            <label htmlFor={`radio-${index}`}>{option.name}</label>
          </div>
        </button>
      ))}
    </div>
  );
};

const PageUserManagerComponent = props => {
  const [components, setComponents] = useState([]);
  const [users, setUsers] = useState([]);
  const stateApiData = useSelector(state => state.apiData.apiData);
  const userData = useSelector(state => state.auth);
  const dispatch = useDispatch();

  /* eslint-disable */
  useEffect(() => {
    function setData() {
      if (props.data_api_key) {
        props.setWidgetData(prev => ({
          ...prev,
          [`data_api_key`]: props.data_api_key,
          [`data_type`]: props.type,
        }));
        dispatch(ApiDataActions.getUsers(props, userData.token));
      } else {
        setUsers(Object.values(props.data));
      }
    }
    setData();
  }, []);

  useEffect(() => {
    const apiData =
      stateApiData &&
      Object.entries(stateApiData).filter(([key, value]) => key === props.data_api_key)[0];
    if (apiData) {
      if (apiData[1]) {
        setUsers(apiData[1].users);
      } else {
        setUsers([]);
      }
    } else {
      setUsers([]);
    }
  }, [stateApiData]);
  /* eslint-enable */

  const actions = useMemo(
    () =>
      Object.values(props.actions || {}).map(action => {
        const res = { ...action };
        if (res.onclick) {
          res.onClick = () => {
            setComponents([]);
            setTimeout(() => setComponents(Object.values(res.onclick)));
          };
        }
        return res;
      }),
    [props, setComponents],
  );

  const options = useMemo(
    () =>
      Object.values(props.options || {}).map(action => {
        const res = { ...action };
        if (res.onclick) {
          res.onClick = () => {
            setComponents([]);
            setTimeout(() => setComponents(Object.values(res.onclick)));
          };
        }
        return res;
      }),
    [props, setComponents],
  );

  return (
    <>
      <UserManager users={users} options={options} actions={actions} />
      {components.map(component => (
        <PageBuilderComponent
          setStepData={props.setStepData}
          setWidgetData={props.setWidgetData}
          stepData={props.stepData}
          widgetData={props.widgetData}
          {...component}
        />
      ))}
    </>
  );
};

// Componente tabs
const PageBuilderTabsComponent = ({ tabs: t = [] }) => {
  // Controla en el estado del componente la pestaña activa
  const [activeTab, setActiveTab] = React.useState(0);

  const tabs = useMemo(() => Object.values(t), [t]);

  return (
    <div className={styles.tabsComponentContainer}>
      {/* Renderizado de pestañas en base a datos recibidos por la prop data */}
      <div className={styles.tabsContainer}>
        {tabs.map((tab, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          // eslint-disable-next-line
          <div
            className={`${styles.tabTitleButton} ${
              index === activeTab ? styles.tabTitleButtonActive : styles.tabTitleButtonInactive
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </div>
        ))}
      </div>
      {/* Renderizado del contenido de la tab, recibido por la propiedad content de la prop data */}
      <div className={styles.tabContentContainer}>
        {tabs.map(
          (tab, index) =>
            index === activeTab &&
            Object.values(tab.components || {}).map(component => (
              <PageBuilderComponent {...component} />
            )),
        )}
      </div>
    </div>
  );
};

const PageBuilderWidgetSwitchComponent = props => {
  const { stepData, setStepData } = props;
  const [widgetData, setWidgetData] = useState({});

  const [checked, setChecked] = useState(props.toggled_by_default || false);

  React.useEffect(() => {
    console.log(' WIDGET DATA IN ', widgetData);
  }, [widgetData]);

  const toggle = useCallback(() => {
    if (props.title || props.name) {
      setStepData(prev => ({
        ...prev,
        [props.index]: {
          ...prev[props.index],
          [props.title || props.name]: !checked,
        },
      }));
    }
    setChecked(!checked);
  }, [checked, setChecked, setStepData, props]);

  return (
    <>
      <div className={`${styles.optimizationSwitchComponentContainer} ${props.title} `}>
        <div className={styles.optimizationWidgetHeaderContainer}>
          <span className={`${styles.optimizationWidgetTitle} optimizationTitle `}>
            {props.title}
          </span>
          {props.labeloff && <b>{props.labeloff}</b>}
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '50%',
              opacity: props.disabled_toggle ? '0' : '1',
            }}
          >
            <Switcher.Switch onChange={toggle} checked={checked} />
          </span>
          <span className={`${styles.widgetDescription} widgetDescription`}>{props.text}</span>
        </div>
        {props.labelon && <b>{props.labelon}</b>}
        {checked &&
          props.components &&
          Object.values(props.components).map(comp => (
            <div style={{ display: 'block', paddingTop: '5px' }}>
              <PageBuilderComponent
                stepData={stepData}
                setStepData={props.setStepData}
                {...comp}
                selectedInstruments={props.selectedInstruments}
                setWidgetData={setWidgetData}
                kind="component"
              />
            </div>
          ))}
        {props.onclick &&
          checked &&
          Object.values(props.onclick).map(comp => (
            <PageBuilderComponent
              stepData={stepData}
              selectedInstruments={props.selectedInstruments}
              setStepData={props.setStepData}
              setWidgetData={setWidgetData}
              {...comp}
            />
          ))}
        {/* {checked && (
          <div className={styles.applyDeleteContainer}>
            <BitaButton
              onClick={() => addToSummary({ [props.title]: widgetData })}
              className={styles.applyDeleteButton}
              width="90px"
              height="30px"
              primary
            >
              Apply
            </BitaButton>
            <BitaButton
              onClick={() => handleClickDelete()}
              className={styles.applyDeleteButton}
              width="90px"
              height="30px"
              primary
            >
              Delete
            </BitaButton>
          </div>
        )} */}
      </div>
    </>
  );
};

const PageBuilderSwitchHolder = ({ Component, ...props }) => {
  const [switchOnIndex, setSwitchOnIndex] = useState(null);

  const resetSwitches = () => setSwitchOnIndex(null);

  return Object.values(props.options).map((option, index) => (
    <Component
      {...props}
      title={null}
      name={option.name}
      groupSwitch={{ switchOn: index === switchOnIndex, resetSwitches, setSwitchOnIndex, index }}
      components={null}
      {...option}
    />
  ));
};

const PageBuilderCheckboxComponent = props => {
  const [checked, setChecked] = useState(false);
  return (
    <div>
      <BitaCheckbox label={props.title} checked={checked} handleChange={setChecked} />
    </div>
  );
};

const PageBuilderInputComponent = props => {
  const { stepData, setStepData, setWidgetData } = props;
  const [value, setValue] = useState('');

  React.useEffect(() => {
    if (props.title && stepData && props.index) {
      setValue(
        stepData[props.index][props.title] && stepData[props.index][props.title][props.title],
      );
    }
    if (props.name && stepData && props.index) {
      setValue(
        stepData[props.index][props.name] &&
          stepData[props.index][props.name][props.external_title],
      );
    }
  }, [stepData, setValue, props]);

  const onChange = onChangeValue => {
    if (setWidgetData) {
      setWidgetData(prev => ({
        ...prev,
        [`${props.external_title || props.title || 'currencyDropdown'}`]: onChangeValue,
      }));
    }

    setValue(onChangeValue);
    if (!setStepData) {
      setValue(onChangeValue);
    }
    if (props.title && setStepData && props.index) {
      setStepData(prev => ({
        ...prev,
        [props.index]: {
          ...prev[props.index],
          [props.title]: {
            ...prev[props.index][props.title],
            [props.title]: onChangeValue,
          },
        },
      }));
    }
    if (props.external_title && setStepData && props.index) {
      setStepData(prev => ({
        ...prev,
        [props.index]: {
          ...prev[props.index],
          [props.external_title]: onChangeValue,
        },
      }));
    }
    if (!props.external_title && !props.title && setStepData && props.index) {
      setStepData(prev => ({
        ...prev,
        [props.index]: {
          ...prev[props.index],
          value: onChangeValue,
        },
      }));
    }
  };
  return (
    <div
      className={` ${styles.optimizationInputComponentContainer} optimizationInputComponentContainer`}
    >
      <BitaInput
        inputContainerStyle={styles.optimizationInputContainer}
        externalTitle={props.external_title}
        externalTitleStyle={styles.optimizationInputTitle}
        placeholder={props.placeholder}
        type={props.typeinput}
        id={props.id}
        maxlength={props.maxlength}
        text={props.text}
        inputStyle={styles.widgetInput}
        labelinput={props.labelinput}
        title={props.title}
        value={value}
        postText={props.post_text}
        handleChange={onChange}
      />
    </div>
  );
};

const PageBuilderDropdownComponent = props => {
  const { options, stepData, setStepData, setWidgetData, onChangeDropdown } = props;
  const [defaultValue, setDefaultValue] = useState();
  const [optionSelec, setOptionSelec] = useState(defaultValue || false);
  const [components, setComponents] = useState([]);
  const showContentManager = useSelector(state => state.upload.showContentManager);

  useEffect(() => {
    // eslint-disable-next-line
    if (components.length === 0 && !defaultValue) {
      if (props.default_nro_option || props.default_nro_option === 0) {
        const auxDefaultOptions = Object.values(options);
        setDefaultValue(auxDefaultOptions[props.default_nro_option]);
        setComponents([]);
        setComponents(
          auxDefaultOptions[props.default_nro_option].onclick
            ? Object.values(auxDefaultOptions[props.default_nro_option].onclick)
            : [],
        );
      }
    }
  }, [props.default_nro_option, options, components, defaultValue]);

  const data = useMemo(() => {
    return Object.entries(options).map(([id, value]) => {
      if (typeof value === 'string' || typeof value === 'number') {
        return { id, value, disabled: value.disabled ? value.disabled : false };
      }
      return { id, value: value.value, disabled: value.disabled ? value.disabled : false };
    });
  }, [options]);

  const onChange = useCallback(
    formatted => {
      if (stepData && stepData[props.index]) {
        setStepData(prev => ({
          ...prev,
          [props.index]: {
            ...prev[props.index],
            [props.title]: { ...prev[props.index][props.title], selectValue: formatted.value },
          },
        }));
      }

      // eslint-disable-next-line no-unused-vars
      Object.entries(props.options).forEach(([_, value]) => {
        if (typeof value === 'string') {
          return;
        }
        if (value.value === formatted?.value) {
          setComponents([]);
          setComponents(value.onclick ? Object.values(value.onclick) : []);
        }
      });
    },
    [setStepData, stepData, props, setComponents],
  );

  React.useEffect(() => {
    const _optionChange = data.filter(item => item.value === showContentManager)[0];
    if (showContentManager && props.id === 'content_manager' && _optionChange) {
      setOptionSelec(_optionChange);
      onChange(_optionChange);
    }
  }, [showContentManager]);

  return (
    <div className={`${styles.dropdownLine} dropdownLine`}>
      {props.external_title || props.title ? (
        <div className={`${styles.dropdownTitle} dropdownTitle `}>
          {props.external_title || props.title}
        </div>
      ) : null}
      <BitaSelect
        className={styles.dropdownContainer}
        data={data}
        label={props.label || 'Select'}
        handleSelection={sel => {
          setWidgetData(prev => ({
            ...prev,
            [`${props.external_title || props.title || 'Value'}`]: { ...sel },
          }));
          onChange(sel);
          setOptionSelec(sel);
          if (onChangeDropdown && sel) onChangeDropdown(sel.value);
        }}
        selectClassname={styles.optimizedSelect}
        value={optionSelec || defaultValue}
        justify={props.justify}
        clearable={false}
      />
      {props.external_titlesecundario ? (
        <div className={styles.dropdownTitle}>{props.external_titlesecundario}</div>
      ) : null}
      {props.post_text && <span className={styles.dropdownPostText}>{props.post_text}</span>}
      {components.map((comp, index) => (
        <PageBuilderComponent
          {...props}
          title={null}
          name={null}
          {...comp}
          dropdownSelected={optionSelec?.value ?? defaultValue?.value}
          key={index}
        />
      ))}
    </div>
  );
};

const PageBuilderAddibleRowsComponent = props => {
  const [subComponents, setSubcomponents] = useState([Object.values(props.subcomponents)]);

  const addNew = useCallback(() => {
    setSubcomponents([...subComponents, Object.values(props.subcomponents)]);
  }, [subComponents, setSubcomponents, props.subcomponents]);

  const remove = useCallback(
    index => {
      setSubcomponents(subComponents.filter((_, i) => i !== index));
    },
    [setSubcomponents, subComponents],
  );

  return (
    <>
      {subComponents.map((group, index) => (
        <div className={`${styles.addibleRow} addibleRow `}>
          {group.map(comp => (
            <PageBuilderComponent
              {...props}
              title={null}
              name={null}
              {...comp}
              addibleTitle={props.title}
            />
          ))}
          <BitaButton className={styles.addibleRowRemoveButton} onClick={() => remove(index)}>
            &times;
          </BitaButton>
        </div>
      ))}

      <BitaButton className={styles.addibleRowPlusButton} onClick={addNew}>
        +
      </BitaButton>
    </>
  );
};

const PageBuilderBrandingInput = ({
  handleChange,
  widgetData,
  id,
  external_title,
  disabled,
  typeinput,
}) => {
  const [value, setValue] = useState(widgetData?.[id] || '');
  const userdata = useSelector(state => state.auth.company);
  const [show, setShow] = useState(false);
  const onChange = val => {
    setValue(val);
    handleChange(val);
  };

  useEffect(() => {
    if (id === 'company') {
      setValue(userdata);
    }
  }, [userdata]);
  const closeEditor = () => {
    setShow(false);
  };

  const saveMultimedia = (mediaValue, type) => {
    handleChange(type, mediaValue);
  };

  return (
    <div key={`${external_title}-${id}`} style={{ flexDirection: 'row' }}>
      <BitaBrandingModal
        open={show}
        closeModal={closeEditor}
        logo={widgetData.logo || false}
        website={
          widgetData.website && widgetData.website !== 'false' && widgetData.website.length > 0
            ? widgetData.website && widgetData.website
            : false
        }
        company={widgetData.company}
        saveChange={saveMultimedia}
        type="multimedia"
        description='Please upload a ".SVG" or ".PNG" image that complies with the following aspects: transparent background and dimension of 120 x 55 px.'
        title={value}
      />
      <BitaInput
        inputContainerStyle={styles.optimizationInputContainer}
        externalTitle={external_title}
        externalTitleStyle={styles.brandingTitle}
        type={typeinput}
        id={id}
        inputStyle={styles.widgetInput}
        value={value}
        handleChange={onChange}
        showAsLabel={disabled}
      />
      <BitaButton primaryWhite className={styles.brandingTextButton} onClick={() => setShow(true)}>
        Edit
      </BitaButton>
    </div>
  );
};

const PageBuilderTextField = ({
  id,
  external_title,
  widgetData,
  handleChange,
  disabled,
  validation,
  description,
}) => {
  const [textValue, setText] = useState(widgetData?.[id]);
  const [show, setShow] = useState(false);
  const openEditor = () => {
    setShow(true);
  };
  const closeEditor = () => {
    setShow(false);
  };
  const editText = newValue => {
    setText(newValue);
    handleChange(id, newValue);
  };
  return (
    <div className={styles.brandingTextContainer} key={`${external_title}-${id}`}>
      <BitaBrandingModal
        title={external_title}
        init_value={textValue}
        open={show}
        closeModal={closeEditor}
        saveChange={editText}
        description={description}
        validation={validation}
        type="textarea"
      />
      <span className={styles.brandingTitle}>{external_title}</span>
      <BitaButton
        primaryWhite
        disabled={disabled || false}
        className={styles.brandingTextButton}
        onClick={() => openEditor()}
      >
        Edit
      </BitaButton>
    </div>
  );
};

export default PageBuilderComponent;
